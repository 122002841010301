import React, { ReactElement } from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
} from "react-share";
import FbIcon from "../SVG/SocialIcons/fb";
import LinkedinIcon from "../SVG/SocialIcons/linkedin";
import PinterestIcon from "../SVG/SocialIcons/pinterest";
import TwitterIcon from "../SVG/SocialIcons/twitter";
import LinkIcon from "../SVG/SocialIcons/linkIcon";
import { SocialIcon } from "./SocialIcon";
import { ArticleData } from "./types";
import { withCopyTooltip } from "../Tooltip";

const CopyIconButton = () => {
  return <SocialIcon icon={LinkIcon} fill={"#526df2"} fillHover={"#49e3be"} />;
};

interface CopyIconButtonProps {
  url: string;
}
const IconWithCopyTooltip = ({ url }: CopyIconButtonProps) => {
  const CopyTooltip = withCopyTooltip(CopyIconButton, url);
  return <CopyTooltip />;
};

const IconsData = [
  {
    shareComponent: function getComponent(
      url: string,
      index: number,
      articleData: ArticleData
    ): ReactElement {
      return (
        <FacebookShareButton
          url={url}
          key={index}
          quote={articleData.description}
          hashtag={articleData.hashtag}
        >
          <SocialIcon icon={FbIcon} fill={"#526df2"} fillHover={"#3b5998"} />
        </FacebookShareButton>
      );
    },
  },
  {
    shareComponent: function getComponent(
      url: string,
      index: number,
      articleData: ArticleData
    ): ReactElement {
      return (
        <LinkedinShareButton
          url={url}
          key={index}
          title={articleData.title}
          summary={articleData.description}
          source={url}
        >
          <SocialIcon
            icon={LinkedinIcon}
            fill={"#526df2"}
            fillHover={"#0e76a8"}
          />
        </LinkedinShareButton>
      );
    },
  },
  {
    shareComponent: function getComponent(
      url: string,
      index: number,
      articleData: ArticleData
    ): ReactElement {
      return (
        <PinterestShareButton
          url={url}
          key={index}
          media={articleData.icon}
          description={articleData.description}
        >
          <SocialIcon
            icon={PinterestIcon}
            fill={"#526df2"}
            fillHover={"#bd081c"}
          />
        </PinterestShareButton>
      );
    },
  },
  {
    shareComponent: function getComponent(
      url: string,
      index: number,
      articleData: ArticleData
    ): ReactElement {
      return (
        <TwitterShareButton
          url={url}
          key={index}
          title={articleData.title}
          hashtags={[articleData.hashtag]}
        >
          <SocialIcon
            icon={TwitterIcon}
            fill={"#526df2"}
            fillHover={"#00acee"}
          />
        </TwitterShareButton>
      );
    },
  },
  {
    shareComponent: function getComponent(
      url: string,
      index: number
    ): ReactElement {
      return <IconWithCopyTooltip key={index} url={url} />;
    },
  },
];

export default IconsData;
