import React, { useState } from "react";

interface SocialIconProps {
  fill: string;
  fillHover: string;
  icon: any;
}

const getIconComponent = (Icon: any, fill: string) => {
  return <Icon fill={fill} />;
};

export const SocialIcon = ({ fill, fillHover, icon }: SocialIconProps) => {
  const [iconColor, setIconColor] = useState("#526df2");
  return (
    <div
      style={{ cursor: "pointer" }}
      onMouseOver={() => {
        setIconColor(fillHover);
      }}
      onMouseOut={() => {
        setIconColor(fill);
      }}
    >
      {getIconComponent(icon, iconColor)}
    </div>
  );
};
