import React, { ReactElement } from "react";

interface TwitterIconProps {
  fill: string;
}

const TwitterIcon = ({ fill }: TwitterIconProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <path
        fill={fill || "#526DF2"}
        fillRule="evenodd"
        d="M20 0c11.046 0 20 8.954 20 20s-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0zm3.098 12.538c-1.803.971-2.732 3.041-2.263 5.045-3.63-.183-7.011-1.908-9.304-4.746-.4.693-.612 1.482-.61 2.284 0 1.575.796 2.966 2.007 3.78-.717-.022-1.417-.217-2.044-.568v.057c0 2.162 1.515 4.025 3.62 4.454-.664.181-1.362.208-2.039.078.59 1.851 2.285 3.119 4.217 3.155-1.891 1.495-4.295 2.171-6.682 1.881 2.063 1.335 4.465 2.044 6.918 2.042 8.303 0 12.844-6.921 12.844-12.924 0-.196-.006-.393-.014-.587.883-.642 1.646-1.439 2.252-2.351l-.001-.001c-.824.367-1.698.608-2.593.715.944-.568 1.65-1.461 1.986-2.514-.885.53-1.855.902-2.866 1.103-1.397-1.504-3.624-1.875-5.428-.903z"
      />
    </svg>
  );
};

export default TwitterIcon;
