import styled, { css } from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import Pagination from "@material-ui/lab/Pagination";
import { Link as gatsbyLink } from "gatsby";

import { Button } from "../Button";
import { ReactComponent as ShareImage } from "../../assets/share-24.svg";
import { ReactComponent as CloseIcon } from "../../assets/close.svg";
import linkIcon from "../SVG/SocialIcons/linkIcon";

export const HeaderContainer = styled.ul`
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  margin: 0;
  max-width: 900px;
`;

export const HeaderItem = styled.li<MenuItemProps>`
  color: #263242;
  font-size: 14px;
  font-weight: bold;
  list-style: none;
  max-width: 750px;
  cursor: pointer;
  ${(props) =>
    props.isActive &&
    css`
      color: #4f55f7;
    `}
`;

export const HeaderBlock = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  border: 1px solid #ecf1f3;
  @media (max-width: 767px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const CategoryCard = styled.div`
  border-radius: 18px;
  border: solid 2px #ecf1f3;
  display: flex;
  align-items: center;
  height: 300px;
  padding-left: 67px;
  justify-content: space-between;
  margin-top: 63px;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 390px;
`;

export const Title = styled.h2`
  font-size: 48px;
  font-weight: bold;
  color: #263242;
`;

export const Description = styled.div`
  font-size: 14px;
  color: #263242;
`;

export const IconContainer = styled.div`
  width: 320px;
  overflow: hidden;
  height: 296px;
  border-radius: 18px;
`;

export const ArticlesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 42px;
  margin-bottom: 42px;
  width: 1200px;
  align-items: stretch;
  max-width: 100%;
  margin: 0 auto;
  @media (min-width: 992px) and (max-width: 1130px) {
    width: 770px;
  }

  @media (max-width: 992px) {
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const ArticleBlock = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 360px;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 18px;
  border: solid 2px #d5dbdd;
  padding-bottom: 24px;
  overflow: hidden;
  @media (max-width: 992px) {
    width: 47%;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    width: 100%;
    margin: 0 auto 30px auto;
  }
`;

export const IconWrapper = styled.div`
  height: 50%;
  height: 271px;
  display: grid;
  overflow: hidden;
  display: flex;
  justify-content: center;
`;

export const ArticleCardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 24px;
`;

export const ArticleCardBottom = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
`;

export const TipTitle = styled.div`
  color: #263242;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
`;

export const TipDescription = styled.div`
  font-size: 14px;
  color: #263242;
`;

export const Link = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #4f55f7;
`;

export const ShareButton = styled(Button)`
  width: 35%;
  max-width: 220px;
  background-color: #ffffff;
  color: #4646eb;
  border: 1px solid #abb3b5;
  padding: 0;
  height: 44px;
`;

export const ShareButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-left: 5px;
  @media (max-width: 1130px) {
    font-size: 15px;
  }
`;

export const ReadMoreButton = styled(Button)`
  width: 65%;
  height: 44px;
  padding: 0;
  margin-right: 20px;
  @media (max-width: 1130px) {
    font-size: 15px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 24px 0 0 0;
`;

export const ShareIcon = styled(ShareImage)`
  width: 24px;
  height: 24px;
  margin-right: 5px;
  @media (max-width: 992px) {
    width: 19px;
    height: 24px;
    margin-top: 4px;
  }
`;

export const ArticleCategory = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  color: #526df2;
  margin-top: 0;
  margin-bottom: 24px;
`;

export const ArticleTitle = styled.p`
  font-size: 22px;
  font-weight: bold;
  line-height: 1.32;
  color: #263242;
  margin-top: 0;
  margin-bottom: 24px;
`;

export const ArticleAuthorInfo = styled.p`
  font-size: 12px;
  line-height: 1.33;
  color: #abb3b5;
  margin-top: 0;
  margin-bottom: 0;
`;

interface MenuItemProps {
  isActive: boolean;
}

interface PaginationPageProps {
  selected: boolean;
}

export const AccordionTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.36;
  color: #abb3b5;
`;

export const AccordionDescription = styled.div<MenuItemProps>`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.36;
  color: #263242;
  margin-bottom: 15px;
  cursor: pointer;
  ${(props) =>
    props.isActive &&
    css`
      color: #4f55f7;
    `}
`;

export const bgBanner = styled.div`
  width: 100%;
  height: 500px;
`;

export const CloseButton = styled(CloseIcon)`
  width: 42px;
  height: 42px;
  cursor: pointer;
  position: absolute;
  top: 35px;
  right: 35px;
  @media (max-width: 767px) {
    top: 29px;
    right: 15px;
  }
`;

export const ModalContent = styled(Dialog)`
  & .MuiPaper-root {
    padding: 42px 36px;
    border-radius: 10px;
    width: 555px;
    @media (max-width: 767px) {
      padding: 32px 16px;
      width: 100%;
      max-width: 100%;
      height: 100vh;
      margin: 0;
      border-radius: initial;
    }
  }
  & .MuiDialogContent-root:first-child {
    padding: 0;
    padding-top: 0;
  }
  & .MuiDialog-paperScrollPaper {
    max-height: 100%;
  }
`;

export const ModalTitle = styled.p`
  font-size: 22px;
  font-weight: bold;
  line-height: 1.32;
  color: #263242;
  margin-top: 0;
`;

export const ModalDescription = styled.p`
  font-size: 16px;
  line-height: 1.31;
  color: #263242;
  margin-bottom: 36px;
`;

export const LinkIcon = styled(linkIcon)`
  ${(props) => css`
    fill: ${props.fill};
  `}
`;

export const IconsContainer = styled.div`
  display: flex;
  width: 360px;
  justify-content: space-between;
  margin: 0 auto;
  @media (max-width: 767px) {
    max-width: 340px;
    width: 100%;
    box-sizing: border-box;
  }
`;

export const IconsFixedContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  & button {
    margin-bottom: 24px;
  }
`;

interface PaginationButtonProps {
  buttonName: string;
}

export const PaginationButton = styled.button<PaginationButtonProps>`
  font-size: 17px;
  font-weight: bold;
  line-height: 1.29;
  text-align: center;
  color: #abb3b5;
  cursor: pointer;
  background: none;
  border: none;
  margin-right: ${(props) => (props.buttonName === "next" ? 0 : "10px")};
  margin-left: ${(props) => (props.buttonName === "prev" ? 0 : "10px")};
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;
    `}
`;

export const PaginationPage = styled.button<PaginationPageProps>`
  cursor: pointer;
  background: none;
  border: none;
  font-size: 17px;
  font-weight: bold;
  line-height: 1.29;
  text-align: center;
  color: #abb3b5;
  width: 40px;
  height: 40px;
  ${(props) =>
    props.selected &&
    css`
      border-radius: 40px;
      background-color: #4f55f7;
      color: #ffffff;
    `}
`;

export const PaginationEllipsis = styled.div`
  color: #abb3b5;
  font-size: 17px;
  font-weight: bold;
  line-height: 1.29;
  text-align: center;
  width: 40px;
`;

export const PaginationBlock = styled(Pagination)`
  display: flex;
  justify-content: center;
`;

export const CarouselBlock = styled.div`
  background-color: #f6f9fa;
  padding-top: 62px;
  margin-top: 62px;
  & .carouselTitle {
    margin-top: 0;
  }
`;

export const BlogHeader = styled.h2`
  font-size: 48px;
  font-weight: bold;
  color: #263242;
  margin-top: 63px;
  margin-bottom: 42px;
  @media (max-width: 767px) {
    margin-top: 0;
  }
`;

export const BlogWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
`;

export const ArticleContent = styled.div`
  position: relative;
  background-color: #ffffff;
  border-radius: 18px;
  padding: 60px 90px 0 90px;
  top: -90px;
  border-radius: 32px;
  padding-bottom: 20px;
  @media (max-width: 767px) {
    top: 0;
    padding: 15px 0 0 0;
    box-shadow: none;
    border: none;
  }
`;

export const ArticleShadow = styled.div`
  height: 90px;
  border-radius: 32px;
  box-shadow: 0 0 60px -20px rgba(60, 60, 113, 0.3);
  position: absolute;
  width: calc(100% - 30px);
  top: -90px;
  border-bottom: none;
  z-index: 0;
`;

export const ArticleWrapper = styled.div`
  position: relative;
  max-width: 945px;
  margin: 0 auto;
  @media (max-width: 1150px) {
    margin-right: 110px;
  }
  @media (max-width: 767px) {
    margin-right: 0;
  }
`;

export const BlogTitle = styled.h2`
  font-size: 34px;
  font-weight: bold;
  line-height: 1.32;
  color: #263242;
  margin-bottom: 48px;
  & .subTitle {
    font-size: 25px;
  }
  @media (max-width: 767px) {
    color: #ffffff;
    font-size: 28px;
    text-align: center;
  }
`;

export const CategoryLink = styled(gatsbyLink)`
  text-decoration: none;
  color: #526df2;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  @media (max-width: 767px) {
    color: #ffffff;
  }
`;

export const AdditionalInfo = styled.div`
  font-size: 16px;
  line-height: 1.31;
  color: #abb3b5;
  margin-bottom: 75px;
  @media (max-width: 767px) {
    color: #ffffff;
  }
`;

export const BlogDescription = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.35;
  color: #263242;
`;

export const BlogContent = styled.div`
  & .chartBlock {
  }
  & h2 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.35;
    color: #263242;
    margin-top: 50px;
    margin-bottom: 32px;
    text-align: justify;
  }
  & p {
    font-size: 20px;
    line-height: 1.35;
    color: #263242;
    margin-bottom: 32px;
    text-align: justify;
    & a {
      color: #4646eb;
      word-break: break-all;
    }
  }
  & li {
    font-size: 20px;
    line-height: 1.35;
    color: #263242;
    text-align: justify;
  }
  & img {
    border-radius: 18px;
    width: 100%;
  }
  & .imagesItems {
    & img {
      width: 25%;
      margin-right: 20px;
      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
      }
    }
    & div {
      display: flex;
      align-content: flex-start;
      align-items: center;
      @media (max-width: 767px) {
        flex-direction: column;
      }
    }
    & p {
      width: 70%;
      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
      }
    }
    & ul {
      width: 70%;
      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
  & .imageItem {
    @media (max-width: 767px) {
      flex-direction: column;
    }
    & img {
      width: 45%;
      float: left;
      margin-right: 20px;
      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 32px;
        float: none;
      }
    }
    & div {
      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
      }
    }
  }

  & .icon27Article7 {
    display: flex;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: center;
    }
    &_img {
      width: 25%;
      max-height: 230px;
      margin-right: 20px;
      margin-top: 20px;

      @media (max-width: 1023px) {
        width: 350px;
        margin-right: 0;
        height: 450px;
        margin-bottom: 32px;
        max-height: none;
      }
      @media (max-width: 767px) {
        max-width: 350px;
        margin-right: 0;
        margin-top: 0;
        max-height: unset;
        height: 500px;
        margin-bottom: 32px;
      }
    }
  }

  & .icon28Article7 {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1023px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    > ul {
      width: 48%;

      @media (max-width: 1023px) {
        width: 100%;
      }
    }

    &_img {
      height: 100%;
      width: 45%;

      @media (max-width: 1023px) {
        width: 55%;
        margin-right: 0;
        height: 480px;
        margin-top: 32px;
      }

      @media (max-width: 767px) {
        max-width: 350px;
        width: unset;
        height: 500px;
      }
    }
  }

  & .icon30Article7 {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1023px) {
      flex-direction: column;
    }

    &_img {
      margin-left: 20px;
      width: 45%;

      @media (max-width: 1023px) {
        width: 80%;
        margin-left: 0;
        margin-bottom: 32px;
      }
    }
  }

  & .icon31Article7 {
    display: flex;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: center;
    }

    &_img1 {
      width: 25%;
      max-height: 250px;
      margin-right: 40px;

      @media (max-width: 1023px) {
        width: 50%;
        margin-right: 0;
        margin-bottom: 32px;
        max-height: unset;
      }
      @media (max-width: 767px) {
        width: 100%;
        max-width: 350px;
      }
    }

    &_img2 {
      width: 25%;
      max-height: 230px;
      margin-left: 20px;

      @media (max-width: 1023px) {
        width: 50%;
        margin-left: 0;
        margin-bottom: 32px;
        max-height: unset;
      }
      @media (max-width: 767px) {
        width: 100%;
        max-width: 350px;
      }
    }
  }

  & .icon34Article7 {
    display: flex;
    justify-content: center;

    & img {
      max-width: 180px;
      max-height: 230px;

      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 32px;
        max-height: 400px;
        max-width: 350px;
      }
    }
  }

  & .icon36Article7 {
    display: flex;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: center;
    }

    &_img1 {
      width: 25%;
      max-height: 250px;
      margin-right: 40px;

      @media (max-width: 1023px) {
        width: 50%;
        margin: 32px 0;
      }
      @media (max-width: 767px) {
        width: 100%;
        max-width: 350px;
      }
    }

    &_img2 {
      width: 25%;
      max-height: 230px;
      margin-left: 20px;

      @media (max-width: 1023px) {
        width: 50%;
        margin-left: 0;
        margin-top: 32px;
      }
      @media (max-width: 767px) {
        width: 100%;
        max-width: 350px;
      }
    }
  }

  & .icon37Article7 {
    display: flex;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: center;
    }

    &_img {
      max-width: 60%;
      max-height: 300px;
      margin-left: 40px;

      @media (max-width: 1023px) {
        width: 80%;
        max-width: none;
        max-height: none;
        margin-left: 0;
        margin-bottom: 32px;
      }
    }
  }

  & .icon38Article7 {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: center;
    }

    &_text {
      width: 48%;

      @media (max-width: 1023px) {
        width: 100%;
      }
    }

    &_img {
      width: 45%;
      max-height: 250px;
      margin-bottom: 20px;

      @media (max-width: 1023px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 32px;
        max-height: unset;
      }
    }
  }

  & .icon39Article7 {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: center;
    }

    &_text {
      width: 48%;

      @media (max-width: 1023px) {
        width: 100%;
      }
    }

    &_img {
      width: 45%;
      max-height: 250px;
      margin-bottom: 20px;

      @media (max-width: 1023px) {
        width: 100%;
        margin-left: 60px;
        margin-right: 0;
        margin-bottom: 32px;
        max-height: unset;
      }
    }
  }

  & .icon1Article8 {
    display: flex;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: center;
    }

    &_img1 {
      width: 30%;
      height: 100%;

      @media (max-width: 1023px) {
        width: 50%;
        margin-right: 0;
        max-height: none;
      }
      @media (max-width: 767px) {
        width: 100%;
        margin-left: 0;
        height: unset;
        max-width: 350px;
      }
    }

    &_img2 {
      width: 30%;
      max-height: 230px;
      margin-left: 20px;

      @media (max-width: 1023px) {
        width: 350px;
        margin-right: 0;
        height: 450px;
        margin-bottom: 32px;
        max-height: none;
      }
      @media (max-width: 767px) {
        width: 100%;
        margin-left: 0;
        height: unset;
        max-width: 350px;
        margin-bottom: 32px;
      }
    }
  }

  @-moz-document url-prefix() {
    & .icon1Article8 {
      &_img1 {
        flex-shrink: 0;
      }

      &_img2 {
        flex-shrink: 0;
      }
    }

    & .icon4Article8 {
      & img {
        flex-shrink: 0;
      }
    }

    & .article9Image {
      &_image {
        flex-shrink: 0;
      }
    }
  }

  & .icon4Article8 {
    display: flex;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: center;
    }

    & img {
      max-width: 60%;
      max-height: 300px;
      margin-left: 10px;

      @media (max-width: 1023px) {
        min-width: 100%;
        max-height: none;
        margin-left: 0;
      }
    }
  }

  & .icon5Article8 {
    display: flex;

    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: center;
    }

    & img {
      float: left;
      max-width: 400px;
      margin-right: 20px;

      @media (max-width: 1023px) {
        float: none;
        max-width: unset;
        width: 100%;
        max-height: none;
        margin-right: 0;
        margin-bottom: 32px;
      }
    }
  }

  & .icon6Article8 {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1023px) {
      flex-direction: column;
      justify-content: flex-start;
    }

    &_item {
      width: 48%;
      height: 100%;
      margin-top: 20px;

      @media (max-width: 1023px) {
        width: 100%;
        margin-bottom: 32px;
        margin-top: unset;
      }
    }
  }

  & .links {
    padding-right: 10px;
    word-break: break-all;
  }

  & .article9ListTitle {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    margin-top: 20px;

    &_img {
      width: 80px;
      height: 65px;
    }

    &_text {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  & .article9Image {
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    &_image {
      width: 40%;
      margin-left: 20px;
      height: 100%;

      @media (max-width: 767px) {
        width: 100%;
        margin-bottom: 32px;
        margin-left: unset;
      }
    }
  }

  & .article9_img2 {
    float: right;
    width: 40%;
    margin-left: 20px;

    @media (max-width: 767px) {
      width: 100%;
      margin-bottom: 32px;
      margin-left: unset;
    }
  }

  & .chartItem {
    display: flex;
    align-content: flex-start;
    align-items: center;
    @media (max-width: 767px) {
      flex-direction: column;
    }
    & img {
      width: 45%;
      margin-right: 20px;
      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
      }
    }
    & ul {
      width: 50%;
      & li {
        margin-bottom: 20px;
        & span {
          width: 150px;
          display: inline-block;
        }
      }
      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
      }
    }
  }

  & video {
    max-width: 100%;

    object-fit: contain;
  }

  & .floatingImage {
    & .floatLeft {
      width: 50%;
      margin-right: 20px;
      float: left;
      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
      }
    }
    & .floatRight {
      width: 50%;
      margin-right: 20px;
      float: right;
      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
      }
    }
  }
`;

export const ArticleCardBottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50%;
`;

export const AuthorInfoContainer = styled.div`
  background-color: #f6f9fa;
  position: relative;
  top: -26px;
  @media (max-width: 767px) {
    margin-top: 32px;
  }
`;

export const AuthorInfoContent = styled.div`
  display: flex;
  padding-top: 32px;
  padding-bottom: 32px;
  max-width: 945px;
  padding-right: 97px;
  padding-left: 0;
  margin: 0 auto;
  @media (max-width: 1150px) {
    padding-right: 82px;
    padding-left: 82px;
    margin-right: 110px;
  }
  @media (max-width: 767px) {
    padding-right: 15px;
    padding-left: 15px;
  }
`;

export const AuthorTitle = styled.div`
  font-size: 16px;
  line-height: 1.31;
  color: #abb3b5;
  margin-bottom: 6px;
  @media (max-width: 767px) {
    margin-left: 88px;
  }
`;

export const AuthorName = styled.div`
  font-size: 22px;
  font-weight: bold;
  line-height: 1.32;
  color: #263242;
  margin-bottom: 16px;
  @media (max-width: 767px) {
    margin-left: 88px;
  }
`;

export const AuthorDescription = styled.div`
  font-size: 20px;
  line-height: 1.35;
  color: #263242;
  text-align: justify;
  @media (max-width: 767px) {
    margin-top: 16px;
  }
`;

export const AuthorPhotoDesc = styled.div`
  margin-right: 29px;
  position: absolute;
  width: 68px;
  border-radius: 68px;
  overflow: hidden;
  height: 68px;
  justify-content: center;
  align-items: center;
  display: flex;
  & img {
    height: 68px;
    width: auto;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

export const AuthorPhotoMob = styled.div`
  position: absolute;
  width: 68px;
  border-radius: 68px;
  overflow: hidden;
  height: 68px;
  justify-content: center;
  align-items: center;
  display: flex;
  & img {
    height: 68px;
    width: auto;
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

export const PopularPostsWrapper = styled.div`
  max-width: 945px;
  padding-right: 97px;
  padding-left: 0;
  margin: 0 auto;
  @media (max-width: 1150px) {
    padding-right: 0;
    padding-left: 0;
  }
  @media (max-width: 993px) {
    padding-left: 50px;
  }
`;

export const ShareBlock = styled.div`
  position: absolute;
  top: 80px;
  & span {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.36;
    text-align: center;
    color: #263242;
    margin-bottom: 32px;
    display: block;
  }
`;

export const ShareBlockWrapper = styled.div`
  position: relative;
`;

export const ShareBlockContent = styled.div`
  position: absolute;
  top: 0;
  right: -100px;
  width: 100px;
  height: 100%;
  @media (max-width: 767px) {
    display: none;
  }
  @media (max-height: 399px) {
    display: none;
  }
`;

export const ArticleContentWrapper = styled.div`
  position: relative;
  display: flex;
  max-width: 945px;
  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
  }
`;

export const MainImgWrapper = styled.div`
  height: 500px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
`;

export const MainBannerMob = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 500px;
  max-width: 345px;
  margin: 0 auto;
  @media (min-width: 768px) {
    display: none;
  }
`;

export const MainBannerDesc = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`;

export const ShareBlockMob = styled.div`
  margin-bottom: 15px;
  & button {
    width: 118px;
  }
  @media (min-width: 768px) and (min-height: 400px) {
    display: none;
  }
`;

export const ImgOpacity = styled.div`
  height: 500px;
  width: 100%;
  position: absolute;
  background-color: black;
  opacity: 0.3;
  @media (min-width: 768px) {
    display: none;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 460px;
  margin-top: 64px;
  margin-bottom: 64px;
  & div {
    width: 220px;
    margin-bottom: 0;
  }
  & a {
    width: 220px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    width: 300px;
    & div {
      width: 220px;
      margin-bottom: 20px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 64px;
  margin-bottom: 64px;
  @media (max-width: 767px) {
    width: 220px;
  }
`;

export const AuthorInfo = styled.div`
  margin-left: 88px;
  @media (max-width: 767px) {
    margin-left: 0;
  }
`;
