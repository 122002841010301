import React from "react";
import MuiDialogContent from "@material-ui/core/DialogContent";

import {
  ModalContent,
  CloseButton,
  ModalTitle,
  ModalDescription,
  IconsContainer,
} from "./styled";
import iconsData from "./IconsData";
import { ArticleData } from "./types";

interface ModalProps {
  isOpen: boolean;
  pageUrl: string;
  handleCloseModal: () => void;
  articleData: ArticleData;
}

const Modal = ({ isOpen, handleCloseModal, articleData }: ModalProps) => {
  return (
    <div id="modal">
      <ModalContent
        onClose={handleCloseModal}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
      >
        <MuiDialogContent>
          <CloseButton
            preserveAspectRatio="xMinYMin meet"
            viewBox="0 0 42 42"
            onClick={handleCloseModal}
          />
          <ModalTitle>Artikel teilen</ModalTitle>
          <ModalDescription>
            Link kopieren oder bei Social Media teilen
          </ModalDescription>
          <IconsContainer>
            {iconsData.map((iconData, index) => {
              return iconData.shareComponent(
                `https://stromee.de/blog/${articleData.articleTitle}`,
                index,
                articleData
              );
            })}
          </IconsContainer>
          <div id="textareaContainer" className="textareaContainerMob" />
        </MuiDialogContent>
      </ModalContent>
    </div>
  );
};

export default Modal;
