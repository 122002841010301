import React from "react";

import icon3 from "../../../assets/Article1/picture-blog3.jpg";

import StoreButton from "../../StoreButton";
import styles from "../../Footer/styles.mod.scss";
import { ButtonsWrapper } from "../styled";

const Article1: React.FC = () => {
  return (
    <div>
      <p>
        Wir haben uns natürlich etwas dabei gedacht, als wir unsere stromee App
        entwickelt haben.
      </p>
      <p>
        <i>
          “Gestern auf der Party hatte ich dieses super interessante Gespräch
          über meinen Stromanbieter, das war das Highlight meines Abends!”
        </i>
      </p>
      <p>
        <span>Kommt dir das bekannt vor?</span>
        <br />
        <span>Nein?</span>
        <br />
        <span>Eben, uns auch nicht.</span>
      </p>
      <p>
        Stromanbieter sind nicht unbedingt das mitreißendste Thema und für
        Smalltalk oder als Stimmungsmacher irgendwie auch nicht wirklich zu
        gebrauchen…
      </p>
      <p>
        Aber trotzdem müssen wir uns alle damit beschäftigen, ohne Strom geht es
        schließlich nicht.
      </p>
      <h2>
        Wäre es da nicht toll, wenn das Thema Stromanbieter und Stromabrechnung
        einfacher, angenehmenr und naja, cooler wäre? Wenn es einfach den Sprung
        ins 21. Jahrhundert schaffte? Digitaler - kundenfreundlicher -
        innovativer - intuitiver!
      </h2>
      <p>So ähnlich war unser Gedankengang zu diesem Problem.</p>
      <p>
        Und dann haben wir die App entwickelt 🤷
        <br />
        Ende.
      </p>
      <p>
        Nein, im Ernst:{" "}
        <strong>
          wir wussten von Anfang an, dass wir mit unserer App Vertragsabschluss,
          Stromtarif, Abrechnung, etc. so einfach und übersichtlich wie möglich
          gestalten wollten.{" "}
        </strong>
      </p>
      <p>
        Mit unserem ersten Release ist der Grundstein dafür auch schon gelegt:{" "}
      </p>
      <ul>
        <li>
          Der Anbieterwechsel ist für dich schnell und schmerzlos - praktisch
          nicht der Rede wert. Mit unserer Rechnungs-Upload-Funktion kannst du
          die Wechselarbeit sogar komplett an uns abgeben.
        </li>
        <li>
          Mit der stromee App hast du deinen Vertragsstatus immer im Blick. So
          weißt du genau, wie lang es dauert bis bei dir 100 % stromee aus der
          Steckdose fließt.
        </li>
        <li>
          Deine Tarifübersicht sowie eine ausführliche FAQ-Sammlung sind immer
          nur einen Swipe entfernt.
        </li>
      </ul>
      <h2>
        Natürlich ist das erst der Anfang, unsere App wächst mit deinem Bedarf.{" "}
      </h2>
      <p>
        Wozu gibt es schließlich Teamworkshops, wenn nicht zur Erarbeitung neuer
        interessanter und praktischer Features?
      </p>
      <img alt="image-1" src={icon3} />
      <p>
        Ob es darum geht, Funktionen zu bieten, die einen Mehrwert für dich
        bedeuten, oder die App so zu gestalten, dass sie intuitiv und innovativ
        ist: stromee soll mehr werden als nur eine 08/15 Stromanbieter App.
      </p>
      <p>
        Mit unserem Background aus dem Smart Home Bereich fließt vor allem auch
        viel Know-how über Energy Monitoring und smarten Stromhaushalt mit in
        die App ein. Du sollst deinen Energieverbrauch tracken und ohne Aufwand
        optimieren können. Das ist unser Ziel.
      </p>
      <h2>
        Strom - bei uns in der Version 2.0 - bietet uns den perfekten
        Ausgangspunkt, um eine Energie App zu kreieren, die auf moderne
        Bedürfnisse zugeschnitten ist und Smart Living mit Nachhaltigkeit
        verbindet.
      </h2>
      <p>
        stromee soll Lust auf Stromsparen machen. Da muss auch das App-Umfeld
        stimmig sein: Deshalb integrieren wir jede Menge nützliche und
        unterhaltsame Infos, Tricks und Anleitungen entweder direkt in die App,
        oder haben sie leicht zugänglich auf unserer stromee Website. In
        appetitgerechte Häppchen verpackt, wir wollen dich schließlich
        informieren, nicht mit unnötigem Wissen erschlagen.{" "}
      </p>
      <p>
        Zum Beispiel verraten dir{" "}
        <a href="/#hints">unsere kleinen Energiespartipps</a>, wie du ganz ohne
        Aufwand - quasi nebenbei - im Alltag weniger Strom verbrauchst.
      </p>
      <p>
        In einem Satz:
        <br />
        <strong>
          Mit unserer App soll sich Nachhaltigkeit nicht nur fürs Klima, sondern
          auch für dich lohnen!
        </strong>{" "}
        Jede eingesparte Kilowattstunde schont letztlich ja auch deinen
        Geldbeutel.
      </p>
      <p>
        Es gibt so viele Möglichkeiten und so viele Features, um das zu
        erreichen. Wir freuen uns schon jetzt auf die nächsten anstehenden
        Releases. Bleib gespannt, was da noch alles kommt!
      </p>
      <p>
        Und für all diejenigen, die es jetzt nicht mehr erwarten können,{" "}
        <a href="/">hier</a> geht’s zum Download.
      </p>
      <ButtonsWrapper>
        <StoreButton
          url="https://apps.apple.com/de/app/id1521872083"
          target="_blank"
          text="App Store"
          className={styles.footer__storeButton}
        />
        <StoreButton
          url="https://play.google.com/store/apps/details?id=com.stromee.android"
          target="_blank"
          text="Google Play"
          className={styles.footer__storeButton}
        />
      </ButtonsWrapper>
    </div>
  );
};

export default Article1;
