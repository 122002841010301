import React from "react";

import icon5 from "../../../assets/Article2/carlo.jpg";

import StoreButton from "../../StoreButton";
import styles from "../../Footer/styles.mod.scss";
import { ButtonsWrapper, ButtonWrapper } from "../styled";

const Article2: React.FC = () => {
  return (
    <div>
      <p>
        Die letzten Monate waren arbeitsintensiv und anstrengend, aber wir haben
        es geschafft. stromee hat das Licht der Welt erblickt und wir sind
        superstolz auf das Ergebnis. Unsere Website ist live, unsere stromee App
        ist bereit zum Download.
      </p>
      <ButtonsWrapper>
        <StoreButton
          url="https://apps.apple.com/de/app/id1521872083"
          target="_blank"
          text="App Store"
          className={styles.footer__storeButton}
        />
        <StoreButton
          url="https://play.google.com/store/apps/details?id=com.stromee.android"
          target="_blank"
          text="Google Play"
          className={styles.footer__storeButton}
        />
      </ButtonsWrapper>
      <h2>
        Wir haben mit stromee einen Stromanbieter auf den Markt gebracht, bei
        dem Ökostrom, Nachhaltigkeit und Energieeffizienz Hand in Hand gehen mit
        transparenten Abrechnungen und fairen Tarifen.
      </h2>
      <p>
        Wir bringen mehr Digitalisierung in das Produkt Strom. stromee ist made
        by homee. Die <a href="https://hom.ee/">homee</a> GmbH, mit Sitz in
        Berlin, ist ein Joint Venture der Energie Steiermark in Österreich und
        Codeatelier in Stuttgart, die das Smart Home Gateway homee entwickelt
        hat. Somit vereinen wir in stromee das digitale Know-How aus dem Smart
        Home Bereich mit der Expertise der Energiewirtschaft - eine
        zukunftsträchtige Symbiose, die aus einfachem Strom smarten Strom macht.
      </p>
      <h2>
        Dazu leistet jeder von uns seinen oder ihren ganz eigenen Beitrag:
      </h2>
      <ul>
        <li>
          Da gibt es die, die von morgens früh bis spät in die Nacht arbeiten,
          damit alles bei unserer Website und der stromee App im Zeitplan
          bleibt, stets unsere Vision vor Augen.
        </li>
        <li>
          Dann sind da die, die vor Kreativität übersprudeln, immer eine
          Eingebung haben und Website und App das stromee Design verpassen und
          ihnen Leben einzuhauchen.
        </li>
        <li>
          Dann natürlich unsere Spezialisten aus dem Bereich Energie, die ihre
          Expertise mit einbringen und dafür sorgen, dass unsere Kunden den
          bestmöglichen Service erhalten.
        </li>
        <li>
          Und unsere Smart Home Experten, die darauf achten, in unserer App
          Strom, Energiemanagement und Smart Living zusammen zu bringen.
        </li>
        <li>
          Und am allerwichtigsten: Carlo, unser Bürohund. Als Happiness Manager
          sorgt er unermüdlich für gute Stimmung und achtet darauf, dass wir
          mindestens einmal pro Tag großzügige Streicheleinheiten verteilen -
          wofür er sich ganz selbstlos zur Verfügung stellt. 😉
        </li>
      </ul>
      <img src={icon5} alt="dog" />
      <p>
        Wir sind also ein bunter Haufen (pssst: für das Gruppenbild oben hatten
        wir uns farblich abgestimmt), voller verschiedener Persönlichkeiten und
        Stärken - und das hat stromee bis jetzt nur gut getan.
      </p>
      <h2>
        Wir alle sind überzeugt, dass wir mit unserem Unternehmen dazu
        beitragen, Strom nachhaltiger zu machen und mehr Energieeffizienz in den
        Alltag von uns allen zu bringen.
      </h2>
      <p>
        Ob das, was wir uns mit stromee vorgenommen haben jetzt auch Früchte
        trägt, bleibt natürlich abzuwarten, wir sind da aber sehr
        zuversichtlich.
      </p>
      <p>
        Wir stehen noch ganz am Anfang unserer Reise und hoffen, du bist mit
        dabei, um zu erleben wie wir wachsen und uns entwickeln, und natürlich
        um mit uns gemeinsam Strom zu sparen.
      </p>
      <ButtonWrapper>
        <StoreButton
          url="/#hints"
          target="_blank"
          text="Energiespartipps"
          className={styles.footer__storeButton}
        />
      </ButtonWrapper>
      <p>
        Es gibt sicher noch viel zu tun, allen voran möchten wir unsere Website
        und unsere stromee App in den kommenden Monaten weiter ausbauen und für
        dich mit neuen Inhalten und Features füllen.
      </p>
      <h2>Der Anfang ist gemacht, wir sind gespannt wie es weiter geht.</h2>
      <ButtonsWrapper>
        <StoreButton
          url="https://apps.apple.com/de/app/id1521872083"
          target="_blank"
          text="App Store"
          className={styles.footer__storeButton}
        />
        <StoreButton
          url="https://play.google.com/store/apps/details?id=com.stromee.android"
          target="_blank"
          text="Google Play"
          className={styles.footer__storeButton}
        />
      </ButtonsWrapper>
    </div>
  );
};

export default Article2;
