import React from "react";

import icon1 from "../../../assets/Article11/blogartikel_banner.png";
import icon2 from "../../../assets/Article11/smart_home_oekostrom.png";
import icon3 from "../../../assets/Article11/smart_home_oekostrom_handy.png";

import StoreButton from "../../StoreButton";
import styles from "../../Footer/styles.mod.scss";
import { ButtonsWrapper } from "../styled";

const Article11: React.FC = () => {
  return (
    <div>
      <p>
        Ökostrom bringt Nachhaltigkeit in deinen Alltag, Smart Home macht ihn
        energieeffizient. Die perfekte Kombination also, um deinen
        Energiehaushalt rundum zu optimieren und Geld zu sparen. Und Gutes für
        die Umwelt zu tun.
      </p>

      <h2>Ökostrom heißt nicht automatisch Energieeffizienz</h2>
      <div className="floatingImage">
        <img
          src={icon2}
          alt="Ökostrom und Energieklassen Haus"
          className="floatRight"
        />
        <p>
          Ökostrom ist mittlerweile eigentlich schon Standard beim Stromangebot.
          Aber das alleine{" "}
          <strong>macht dein Zuhause noch nicht energieeffizient</strong> - dein
          Stromverbrauch ändert sich schließlich nicht, nur weil du Ökostrom
          nutzt.
          <br />
          Natürlich gibt es jede Menge Tipps und Tricks, wie du nebenbei im
          Alltag Strom und Energie sparen kannst: mit dem Wasserkocher das
          Nudelwasser erhitzen, <strong>Standby Modus ausschalten,</strong>{" "}
          Waschmaschine auf Niedrigtemperaturen laufen lassen...
        </p>
      </div>

      <p>
        Wir kennen das ja: ein paar Wochen funktioniert das super. Aber immer
        überall checken, ob auch jedes Licht ausgeschaltet ist oder vor jedem
        Ausflug durch die gesamte Wohnung laufen, nur, um alle Geräte von
        Standby auf “aus” zu schalten? Da wird’s dann irgendwann aufwendig und
        oft genug lässt man es auch ganz schnell wieder sein. 😅
      </p>

      <h2>Auftritt Smart Home</h2>
      <p>
        Mit einer Smart Home Zentrale kannst du deinen Energiehaushalt ganz
        bequem optimieren und einfach Energie sparen.
        <br />
        Wichtig: um das ganze Sparpotenzial ausschöpfen zu können, muss
        natürlich alles richtig vernetzt und geschaltet werden. Sonst kann das
        Smart Home schnell mal zum Stromfresser, statt zum Stromsparer werden.
        Zum Glück gibt’s ja{" "}
        <a
          href="https://hom.ee/blog/?utm_source=stromee_blog&utm_medium=link&utm_campaign=homee_blog"
          target="_blank"
          rel="noreferrer"
        >
          jede Menge Blogartikel
        </a>{" "}
        für Smart Home Einsteiger. 😉
      </p>

      <div>
        <a
          href="https://hom.ee/store/?utm_source=stromee_blog&utm_medium=banner&utm_campaign=homee_blog"
          target="_blank"
          rel="noreferrer"
        >
          <img src={icon1} alt="homee Banner" />
        </a>
      </div>

      <h2>Wie spart Smart Home Energie?</h2>

      <div className="floatingImage">
        <img src={icon3} alt="SmartHome Handy" className="floatLeft" />
        <p>
          Durch intelligente Steuerung können viele Geräte so kurz wie möglich
          angeschaltet werden ohne, dass du dich im Alltag irgendwie
          einschränken musst oder das Gefühl hast, du verzichtest auf
          irgendetwas. Das gilt nicht nur für die Beleuchtung. Vor allem bei den
          “großen Verbrauchern” im Haushalt lässt sich sparen. Da werden zum
          Beispiel Heizung oder Klimaanlage smart und bei dir herrscht rund um
          die Uhr Wohlfühlklima, ohne, dass die Geräte stundenlang “umsonst”
          laufen. Besonders bei Heizkosten kannst du hier eine Menge sparen,
          denn rund 2/3 des Energieverbrauchs in Privathaushalten entfallen auf
          Heizung und Warmwasser.* Aber auch bei Spülmaschine und Co. kannst du
          durch intelligentes Schalten der Betriebszeiten Geld sparen.
        </p>
      </div>

      <h2>Lass dein Smart Home für dich arbeiten</h2>
      <p>
        Der Vorteil beim Smart Home ist klar: du musst dich nicht mehr selber um
        das Sparen kümmern. Kein nerviges Checken, ob auch alle Lichter
        ausgeschaltet sind oder die Heizung oben im Bad abgedreht ist. Das Smart
        Home nimmt dir den Aufwand ab. Und wenn doch mal ein Gerät vergessen
        wurde, behältst du per App auch von unterwegs immer die Kontrolle.
      </p>

      <h2>Jede Menge Sparpotenzial</h2>
      <p>
        Smart Home macht das Energiesparen einfach und bequem, du bekommst im
        besten Fall gar nicht mit, dass du weniger verbrauchst. Deshalb
        funktioniert es so gut.
        <br />
        Tatsächlich schreibt die Deutsche Energie-Agentur solchen digitalen
        Anwendungen bei der Energiewende eine zentrale Rolle zu.** Und einmal
        eingebaut, bietet dir das Smart Home die unterschiedlichsten
        Einsatzmöglichkeiten. Von Zeitschaltplänen über Schaltung durch
        Anwesenheitserkennung bis hin zur optimalen Verknüpfung mehrerer Geräte
        miteinander.
      </p>

      <h2>Die Kombi macht’s</h2>
      <p>
        Auch Energieeffizienz alleine ist nur die halbe Miete. Mit Ökostrom
        machst du das Smart Home nachhaltig.
      </p>

      <ButtonsWrapper>
        <StoreButton
          url="https://stromee.de"
          target="_blank"
          text="Hol dir stromee Ökostrom"
          className={styles.footer__storeButton}
        />
      </ButtonsWrapper>

      <p>
        Ökostrom heißt in die Zukunft investieren und Energie nutzen, die
        unserem Planeten nicht schadet.
        <br />
        Ein Smart Home stellt sicher, dass du diese Energie möglichst effizient
        einsetzt, ohne auf Komfort verzichten zu müssen - im Gegenteil, vieles
        wird dadurch einfacher und bequemer.
        <br />
        <br />
        Die Ökostrom - Smart Home Kombi lohnt sich also. Für die Umwelt, und um
        auf lange Sicht nachhaltig Geld zu sparen.
      </p>

      <span>Quellen:</span>
      <br />
      <br />

      <div className="links">
        *{" "}
        <a
          href="https://www.umweltbundesamt.de/daten/private-haushalte-konsum/wohnen/energieverbrauch-privater-haushalte#hochster-anteil-am-energieverbrauch-zum-heizen"
          target="_blank"
          rel="noreferrer"
        >
          {`https://www.umweltbundesamt.de/daten/private-haushalte-konsum/wohnen/energieverbrauch-privater-haushalte#hochster-anteil-am-energieverbrauch-zum-heizen`}
        </a>
        <br />
        <br />
        **{" "}
        <a
          href="https://www.dena.de/startseite/"
          target="_blank"
          rel="noreferrer"
        >
          {`https://www.dena.de/startseite/`}
        </a>
        <br />
        **{" "}
        <a
          href="https://www.bauemotion.de/special/energieeffizienz-im-smart-home/20900836/"
          target="_blank"
          rel="noreferrer"
        >
          {`https://www.bauemotion.de/special/energieeffizienz-im-smart-home/20900836/`}
        </a>
      </div>
    </div>
  );
};

export default Article11;
