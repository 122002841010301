import React from "react";

import icon1 from "../../../assets/Article8/energielabel_alt.jpg";
import icon2 from "../../../assets/Article8/EEK-alt.jpg";
import icon3 from "../../../assets/Article8/Änderungen_neues_Label.jpg";
import icon4 from "../../../assets/Article8/energieeffizienzklassen_Tabelle.jpg";
import icon5 from "../../../assets/Article8/energieeffizienz_QR.jpg";
import icon6 from "../../../assets/Article8/energieeffizienz_verbrauch.jpg";
import icon7 from "../../../assets/Article8/energieeffizienz_produktinfos.jpg";

const Article8: React.FC = () => {
  return (
    <div>
      <p>
        Energieeffizienzklassen - zu diesem Zungenbrecher gehört das Label an
        Kühlschrank, Fernseher oder Waschmaschine. Genau, das mit den farbigen
        Balken von A+++ bis D.
      </p>
      <p>
        Schau es dir noch einmal ganz genau an, denn ab März 2021 erstrahlt das
        Label im neuen Glanz - mit einigen wichtigen Änderungen.
      </p>
      <p>
        Wie diese Änderungen aussehen und warum es überhaupt eine “Neuauflage”
        gibt, erfährst du in diesem Artikel.
      </p>

      <p>
        <strong>Energieeffizienzklassen - was ist das überhaupt?</strong>
      </p>
      <div className="icon1Article8">
        <img className="icon1Article8_img1" src={icon1} alt="icon" />
        <div>
          <p>
            Wie der Name schon vermuten lässt, kennzeichnen die
            Energieeffizienzklassen den Energieverbrauch unterschiedlicher
            Produkte. Das Energielabel ermöglicht dir also beim Kauf (etwa eines
            neuen Kühlschranks) einen einfachen Vergleich des Energieverbrauchs
            bei unterschiedlichen Modellen.
          </p>

          <p>
            Das Energielabel wurde 1995 von der EU-Kommission eingeführt, mit
            den Klassen A bis G. Damals entsprach das noch den damaligen
            Energiestandards, aber unsere Geräte werden ja laufend
            stromsparender - irgendwann reichte dann Klasse A als oberste
            Einstufung nicht mehr aus.
          </p>
        </div>
      </div>

      <div className="icon1Article8">
        <div>
          <p>
            Deshalb wurden die neuen Klassen A+ bis A+++ hinzugefügt - die
            Klassen E bis G fielen im Gegenzug weg, weil sie nicht mehr relevant
            waren.
          </p>
        </div>
        <img className="icon1Article8_img2" src={icon2} alt="icon" />
      </div>

      <p>
        <strong>Was ändert sich 2021 an den Energieeffizienzklassen?</strong>
      </p>

      <img src={icon3} alt="icon" />

      <p>
        Statt der bis jetzt üblichen Bezeichnungen A+++, A++, A+, A, B, C und D
        gibt es ab 01.03.2021 wieder die “alte” Einstufung in A, B, C, D, E, F
        und G.
      </p>
      <p>
        Das soll mehr Transparenz in das System bringen, denn die ganzen
        Pluszeichen hinter der A-Klasse waren mittlerweile doch etwas
        verwirrend.
      </p>
      <p>
        Seit einigen Jahren sind die meisten Geräte außerdem so stromsparend,
        dass sie nur noch als A+++ eingestuft werden. Damit ist das Energielabel
        überholt und nicht mehr aussagekräftig genug.
      </p>

      <p>
        <strong>Ist also jetzt A das neue A+++?</strong>
      </p>
      <p>Nein, natürlich nicht. Das wäre dann doch zu einfach. 😜</p>
      <p>
        Außerdem würde das nichts an der Einstufungsproblematik ändern: es gäbe
        immer noch eine riesige Gruppe an Geräten, die alle dieselbe
        Energieeffizienzklasse hätten.
      </p>
      <p>
        Die neue Einstufung soll aber die momentanen Standards wieder besser und
        differenzierter abbilden. Deshalb wurden zur Festlegung der einzelnen
        Klassen neue Methoden bei der Messung des Energieverbrauchs angewendet,
        um eine angepasste und auch feinstufigere Abgrenzung vorzunehmen und
        gleichzeitig Raum für Entwicklung zu lassen.
      </p>

      <p>
        <strong>Was genau bedeutet das?</strong>
      </p>
      <p>Eines vorneweg: Ein “A” bekommt erst mal keiner.</p>
      <p>
        Die neue A-Klasse bleibt vorläufig leer, denn: sie steht für den
        anzustrebenden Maßstab, den künftig entwickelte Geräte erfüllen sollen.
        Momentan besitzt keines unserer Geräte diesen Standard. Erst wenn
        Hersteller demnächst besonders effiziente Geräte auf den Markt bringen,
        werden diese als “A” eingestuft.
      </p>
      <p>
        Die alten Klassen A+++ bis D werden also ab März in B bis G neu
        eingestuft. Das funktioniert aber nicht 1:1. Ein Gerät mit Einstufung
        A+++ ist also nicht automatisch Klasse B, sondern kann - je nach seinen
        Energiewerten - zum Beispiel auch in die neue C- oder D-Klasse rutschen.
      </p>

      <p>
        <strong>
          Wichtig bei der Einstufung ist der Energieeffizienzindex (EEI)
        </strong>
      </p>
      <p>
        Der EEI ist ein standardisierter Wert für die einzelnen
        Energieeffizienzklassen. Er setzt sich aus einer ganzen Reihe von
        unterschiedlichen Parametern zusammen. Bei einem Spülmaschine fließen
        zum Beispiel unter anderem der Energieverbrauch für den
        Standard-Spülgang, die Programmdauer, aber auch die Breite des
        Spülmaschines mit in die Berechnungen des EEI mit ein. Bei jedem Gerät
        gibt es dabei natürlich gerätespezifische Parameter, die beachtet werden
        müssen. Das ganze System ist ziemlich komplex. Es gilt aber: je kleiner
        der EEI, desto energieeffizienter das Gerät. <br />
        Für jede Energieeffizienzklasse gibt es nun für jedes Gerät einen
        festgelegten EEI. Wenn neue Geräte ihr Label bekommen, werden sie also
        anhand dieses - von der EU vorgegebenen - Standardwertes gemessen und
        entsprechend eingestuft.
      </p>

      <div className="icon4Article8">
        <p>
          Nehmen wir doch mal den Spülmaschine als Beispiel. Sagen wir, er hat
          einen EEI von 49. Für eine Einstufung als A+++ Gerät war bis jetzt ein
          EEI von {"<"}50 nötig. Passt also.
        </p>

        <img src={icon4} alt="icon" />
      </div>

      <p>
        Und ab März? Da gehört er dann in die neue D-Klasse, für die ein EEI von
        44 bis 50 angesetzt ist.
      </p>

      <p>
        <strong>Also direkt alle Geräte neu kaufen?</strong>
      </p>
      <p>
        Ja! Du solltest dich gleich komplett neu ausstatten 😜 <br />
        Aber im Ernst: es klingt zwar erst mal nicht schön, dass plötzlich all
        deine A++ und A+++ Geräte “nur noch” C oder D Geräte sind. Aber ihr
        Energieverbrauch ändert sich dadurch ja nicht. Die neuen Einstufungen
        haben einfach höhere Anforderungen an die Geräte und es soll ja wie
        gesagt auch Luft nach oben geben. Schließlich soll das Label ja nicht
        jährlich neu angepasst werden.
      </p>

      <p>
        <strong>Und sonst so labelmäßig?</strong>
      </p>

      <p>
        <strong>Zusatzinfos dank QR-Code</strong>
      </p>
      <div className="icon5Article8">
        <p>
          <img src={icon5} alt="icon" />
          Neu ist auch der QR-Code rechts oben auf dem Label. Über ihn kannst du
          ab März auf die European Product Registry for Energy Labelling (also
          europäische Produktdatenbank), kurz “EPREL” zugreifen. Hier
          hinterlegen Hersteller Datenblätter mit Informationen zu allen
          Geräten, die das neue Energielabel benötigen.
        </p>
      </div>

      <p>
        <strong>Energieverbrauch</strong>
      </p>
      <div className="icon5Article8">
        <p>
          <img src={icon6} alt="icon" />
          Der Energieverbrauch des Produktes wurde auf dem Label bis jetzt als
          durchschnittlicher Verbrauch pro Jahr angezeigt. Der Jahresverbrauch
          variiert aber natürlich von Haushalt zu Haushalt stark - zum Beispiel
          läuft in einem 5-Personen-Haushalt die Waschmaschine deutlich häufiger
          als in einem Single-Haushalt. Auf dem neuen Label wird der Verbrauch
          nun pro Betriebsstunde / Nutzungszyklus dargestellt. Damit kann der
          Kunde den tatsächliche Verbrauch viel besser und einfacher
          nachvollziehen.
        </p>
      </div>

      <p>
        <strong>Produkteigenschaften</strong>
      </p>

      <div className="icon6Article8">
        <img className="icon6Article8_item" src={icon7} alt="icon" />
        <p className="icon6Article8_item">
          Für dich als Verbraucher interessante Produkteigenschaften wie
          Wasserverbrauch, Helligkeit, Geräuschemission oder Speicherkapazität
          findest du weiterhin wie gehabt mit auf dem Label.
        </p>
      </div>
      <br />

      <p>
        <strong>Alle neu macht der...März?</strong>
      </p>
      <p>
        Eingeführt wird das neue Label wie gesagt im März. Allerdings nicht
        direkt für alle Produktgruppen, die Einführung erfolgt schrittweise.
      </p>

      <ul>
        <li>
          Im März machen Waschmaschinen und Waschtrockner, Spülmaschinen, Kühl-
          und Gefrierschränke, Fernseher und Monitore den Anfang.
        </li>
        <br />
        <li>
          Im September ziehen dann die Lampen nach - hier müssen alte und neue
          Label dann übrigens anderthalb Jahre parallel gezeigt werden.
        </li>
        <br />
        <li>
          Alle anderen kennzeichnungspflichtigen Elektrogeräte werden
          wahrscheinlich erst ab 2024 neu gelabelt und bei Heizungen ändert sich
          das Label erst ab 2026.
        </li>
      </ul>

      <p>
        <strong>Sind die neuen Labels denn jetzt besser?</strong>
      </p>
      <p>
        Kurz gesagt, ja, wie oben beschrieben gibt es einiges an Verbesserungen.
      </p>

      <ul>
        <li>
          Durch die höheren Anforderungen bei der Klassifizierung befinden sich
          nicht mehr 90 % der Geräte in den Bestklassen.
        </li>
        <br />
        <li>
          Die feinere Abstufungen erlaubt eine genauere und schnellere
          Einschätzung des Energieverbrauchs.
        </li>
        <br />
        <li>
          Die veränderten Prüfverfahren für die einzelnen Geräteklassen kommt
          echten Nutzungsbedingungen im Haushalt jetzt viel näher.
        </li>
        <br />
        <li>
          Die einheitliche Kennzeichnung des Stromverbrauchs macht es viel
          einfacher, den eigenen Stromverbrauch zu ermitteln.
        </li>
      </ul>

      <p>
        Mit den neuen Labels wird es also um einiges einfacher, deinen Alltag
        (noch) energieeffizienter zu gestalten -{" "}
        <strong>stromee approves!</strong> 😉
      </p>

      <span>Quellen:</span>
      <br />
      <br />

      <div className="links">
        <a
          href="https://www.bmwi.de/Redaktion/DE/Publikationen/Energie/das-neue-energielabel.html"
          target="_blank"
          rel="noreferrer"
        >{`https://www.bmwi.de/Redaktion/DE/Publikationen/Energie/das-neue-energielabel.html`}</a>
      </div>
      <br />
      <div className="links">
        <a
          href="https://www.verbraucherzentrale.de/aktuelle-meldungen/energie/elektrogeraete-neue-energielabels-ab-maerz-2021-52005"
          target="_blank"
          rel="noreferrer"
        >{`https://www.verbraucherzentrale.de/aktuelle-meldungen/energie/elektrogeraete-neue-energielabels-ab-maerz-2021-52005`}</a>
      </div>
      <br />
    </div>
  );
};

export default Article8;
