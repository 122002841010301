import React from "react";

import icon1 from "../../../assets/Article10/kohle_icon.png";
import icon2 from "../../../assets/Article10/wasser_icon.png";
import icon3 from "../../../assets/Article10/kohlekraftwerk.png";
import icon4 from "../../../assets/Article10/wasserkraft.png";
import icon5 from "../../../assets/Article10/kraftwerk_zur_steckdose.png";

import StoreButton from "../../StoreButton";
import styles from "../../Footer/styles.mod.scss";
import { ButtonsWrapper } from "../styled";

const Article10: React.FC = () => {
  return (
    <div>
      <p>
        Licht, Herd, Wasserkocher, Fernseher, Staubsauger - ein Haushalt ohne
        elektrische Geräte? Heutzutage kaum vorstellbar.
      </p>
      <p>
        Strom ist wie ein Grundnahrungsmittel - immer verfügbar. Aber wie wird
        Strom erzeugt und wie kommt er eigentlich zu uns nach Hause?
      </p>
      <p>
        “Irgendwo auf einer grünen Wiese dreht sich ein Schwarm Windräder
        fröhlich in der Brise, am Stausee läuft die Turbine des
        Wasserkraftwerks. Dort, oder weniger nachhaltig in Kohlekraftwerken,
        wird dann - irgendwie - Strom erzeugt”
      </p>
      <p>
        So oder ähnlich vage sieht auch deine Vorstellung davon aus, wie genau
        das mit dem Strom funktioniert? Dann bist du hier genau richtig, um zu
        erfahren, was zwischen Stromproduktion und Licht anknipsen alles
        abläuft, damit der Strom in deiner Steckdose landet.
      </p>

      <p>
        <strong>Alles auf Anfang: 2 Arten der Stromerzeugung</strong>
        <br />
        Erst mal die Basics:
      </p>
      <p>
        <strong>
          Es gibt 2 Arten der Stromerzeugung - konventionell und alternativ.
        </strong>
      </p>
      <div className="icon1Article8">
        <img className="icon1Article8_img1" src={icon1} alt="icon" />
        <div>
          <p>
            Konventionell heißt, der Strom stammt aus Kraftwerken, die fossile
            Energieträger (Steinkohle, Braunkohle, Erdgas) nutzen oder er wird
            durch Kernenergie erzeugt. In Deutschland stammt immer noch etwa{" "}
            <strong>
              57,3 %* unseres Strommixes aus solchen konventionellen Kraftwerken
            </strong>
            , wobei Atomenergie aber nur noch einenminimalen Anteil ausmacht:
            die letzten Atomkraftwerkesollen schließlich spätestens 2022
            abgeschaltet werden.
          </p>
        </div>
      </div>
      <div className="icon1Article8">
        <img className="icon1Article8_img1" src={icon2} alt="icon" />
        <div>
          <p>
            Bei der alternativen Stromerzeugung werden erneuerbare
            Energiequellen genutzt, also Wasser- und Windkraft, Solarenergie,
            Erdwärme oder Biomasse. In Deutschland wird bereits knapp{" "}
            <strong>
              die Hälfte unseres Strommixes (42,7 %)* aus erneuerbaren Energien
            </strong>{" "}
            gewonnen.
          </p>
        </div>
      </div>
      <p>
        <strong>
          Fließt bei dir auch schon Ökostrom? Vielleicht überzeugt dich ja 100%
          reine Wasserkraft von stromee.
        </strong>
      </p>
      <ButtonsWrapper>
        <StoreButton
          url="https://stromee.de"
          target="_blank"
          text="Mehr Infos"
          className={styles.footer__storeButton}
        />
      </ButtonsWrapper>
      <p>
        <strong>Das passiert im Kraftwerk</strong>
      </p>

      <p>
        <strong>Mit konventionellen Energieträgern</strong>
      </p>
      <p>
        Bei der Stromerzeugung werden hier vor allem thermodynamische Prozesse
        genutzt, durch die mechanische Energie und Wärme entsteht. Heißt im Fall
        von Kohlekraftwerken: Kohle und Gas verbrennen, erzeugen Wärmeund Dampf.
        Damit werden Turbinen angetrieben, die die Wärmeenergie in
        Rotationsenergie umwandeln. Die Rotationsenergie wird dann wiederum in
        Generatoren in elektrische Energie, also in Strom, umgewandelt.
      </p>

      <img src={icon3} alt="icon" />

      <p>
        <strong>Mit erneuerbaren Energien</strong>
      </p>
      <p>
        Wasser- und Windkraftwerke nutzen das Prinzip der mechanischen
        Energieerzeugung. Das bedeutet, sie wandeln Bewegungsenergie (=
        kinetische Energie) in elektrische Energie um. Ein Wasserkraftwerk
        funktioniert also eigentlich wie eine Mühle - wenn man sich statt des
        Mühlrades eine Turbine denkt, die durch die Bewegungsenergie des Wassers
        angetrieben wird. Und statt des Mühlsteins hat das Wasserkraftwerk einen
        Generator, der mit der Turbine verbunden ist. Der Generator übersetzt
        die erzeugte Bewegungsenergie in elektrischen Strom.
      </p>

      <img src={icon4} alt="icon" />

      <p>Bei einem Windkraftwerk ist der Ablauf ähnlich.</p>
      <p>
        Nur bei Photovoltaik und Brennstoffzellen wird kein Generator genutzt,
        hier entsteht der Strom durch chemische Abläufe.
      </p>

      <p>
        <strong>Strom speichern - aber wie?</strong>
      </p>
      <p>
        Strom erzeugen klappt schon mal super, wenn auch (noch) nicht
        vollständig übererneuerbare Energien. Nur überschüssigen Strom speichern
        ist eine Herausforderung. Eine Möglichkeit ist es, den Strom sozusagen
        vor der Herstellung zu speichern - also den Energieträger für die
        spätere Stromerzeugung bereitzuhalten.Bei Energie aus Wasserkraft
        funktioniert das noch einigermaßen gut. Das Wasser wird dazu in einem
        höher gelegenen Stausee in sogenannten Pumpspeicherkraftwerken
        gespeichert. Damit besitzt es Höhenenergie (Lageenergie), die es an die
        Turbinen abgibt, sobald es bei Bedarf aus dem Stausee nach unten
        rauscht. Eine verlässliche Speichermöglichkeit. Allerdings: so ein
        Speicher braucht enorm viel Platz.
      </p>

      <p>
        <strong>Und ab in die Steckdose</strong>
      </p>
      <p>
        Strom platzsparend langfristig zu speichern, ist also nach wie vor eine
        Herausforderung. Meist speist man den erzeugten Strom deshalb direkt ins
        Stromnetz ein. Über Überlandleitungen, Strommasten und Erdkabel findet
        der Strom seinen Weg ans Wohnhaus, von wo er über die Hauptleitung
        verteilt wird.
      </p>
      <p>
        Auf dem Weg dorthin durchlebt der Strom noch verschiedene Umwandlungen,
        denn er wird mit unterschiedlichen Spannungen transportiert. In
        Deutschland gibt es 4 verschiedene Spannungsebenen:
      </p>

      <ul>
        <li>Höchstspannung</li>
        <br />
        <li>Hochspannung</li>
        <br />
        <li>Mittelspannung</li>
        <br />
        <li>Niederspannung</li>
      </ul>

      <p>
        Die Leitungen funktionieren wie ein Straßennetz. Die
        Höchstspannungsleitungensind die Autobahnen. Hier wird schnell viel
        Strom transportiert. Niederspannungsleitungen sind die 30-er Zonen, wo
        der Strom nur noch langsam - also in kleineren Mengen - weitergeleitet
        wird.
      </p>

      <img src={icon5} alt="icon" />

      <p>
        Übrigens: je höher die Spannung, desto niedriger sind die
        Energieverluste. In den Überlandleitungen herrscht deshalb hohe
        Spannung, denn sie transportieren den Strom auf längeren Strecken.
      </p>

      <p>
        Was dann schließlich bei dir zu Hause ankommt, wurde auf seinem Weg
        durch Umspannwerke auf die Niederspannung von 230 Volt umgewandelt - von
        ursprünglich zwischen 220.000 und 380.000 Volt. Ob du nun einen
        Stromtarif mit norwegischer Wasserkraft oder Windkraft aus
        Norddeutschland hast, der Strom aus deiner Steckdose kommt trotzdem
        meist aus einem Kraftwerk in deiner Nähe, denn auch bei
        Höchstspannungsleitungen gilt: je länger die Strecke, desto mehr
        Energieverlust. Deshalb wird bei der Stromverteilung auf möglichst kurze
        Transportwege geachtet.
      </p>

      <p>
        <strong>Strom ist reine Spekulation</strong>
      </p>

      <p>
        Technisch gesehen ist der Weg vom Kraftwerk bis in deine Wohnung damit
        abgeschlossen - müsste der Strom nicht noch bezahlt werden. Die meisten
        Stromanbieter betreiben die Kraftwerke nicht selbst, sondern kaufen den
        Strom ein. Das passiert in den meisten Fällen an extra dafür
        vorgesehenen Energiebörsen - in Deutschland ist das die Strombörse EEX
        (European Energy Exchange) mit Sitz in Leipzig.
      </p>
      <p>
        Wie bei jeder gewöhnlichen Börse wird bei der EEX zwischen Terminmarkt
        und Spotmarkt unterschieden.
      </p>

      <ul>
        <li>
          <strong>
            Am Terminmarkt kaufen die Energieversorger ihren Strom für die
            Zukunft ein.
          </strong>{" "}
          Zukunft heißt hier alles zwischen 5 Wochen bis hin zu 6 Jahren im
          Voraus.
        </li>
        <br />
        <li>
          <strong>
            Am Spotmarkt werden Bedarfsspitzen für den selben oder nächsten Tag
            eingekauft.
          </strong>{" "}
          Die Preise liegen hier meist höher als auf dem Terminmarkt. Es gibt
          aber auch negative Preise. Die entstehen bei einer hohen Einspeisung
          von Strom aus erneuerbaren Energien oder bei sehr geringem
          Stromverbrauch.
        </li>
      </ul>

      <p>
        Es ist also von Vorteil, den potenziellen Verbrauch gut abzuschätzen, um
        an der Strombörse so günstig wie möglich einkaufen zu können. Eine
        100-prozentig richtige Schätzung für den Terminmarkt ist allerdings nie
        wirklich möglich, dazu gibt es zu viele schwer vorhersehbare
        Schwankungen beim Stromverbrauch. Schließlich kann man nie genau wissen,
        ob und wann ein Verbraucher im Urlaub ist, oder vielleicht mal einen
        Monat verstärkt Home Office macht. Und Großereignisse wie Wetter oder
        auch Pandemien wie Corona haben natürlich auch direkten Einfluss. Ein
        Beispiel: der Sommer ist überdurchschnittlich heiß, es werden deshalb
        mehr Ventilatoren und Klimaanlagen angeschaltet als im Vorjahr. Das
        wirkt sich auf den Stromverbrauch aus. Die Übernahme von
        Erfahrungswerten aus vergangenen Jahren ist also nur bedingt sinnvoll.{" "}
      </p>

      <p>
        Zur Vereinfachung des Ganzen werden deshalb für die einzelnen
        Verbrauchergruppen Standardprognosen, die sogenannten
        Standardlastprofile, verwendet.
      </p>

      <p>
        <strong>
          Kennst du übrigens schon unsere kostenlose stromee App? Dort erstellen
          wir für dich eine individuelle Verbrauchsprognose, um dir mehr zu
          bieten, als einfach nur Standard.
        </strong>
      </p>
      <ButtonsWrapper>
        <StoreButton
          url="https://apps.apple.com/de/app/id1521872083"
          target="_blank"
          text="App Store"
          className={styles.footer__storeButton}
        />
        <StoreButton
          url="https://play.google.com/store/apps/details?id=com.stromee.android"
          target="_blank"
          text="Google Play"
          className={styles.footer__storeButton}
        />
      </ButtonsWrapper>

      <p>
        <strong>Preisgeschichten</strong>
      </p>
      <p>
        Der Strom wird kontinuierlich an der Börse ge- und verkauft, Angebot und
        Nachfrage bestimmen den Preis. Und auch Steuern, Abgaben und Umlagen
        können sich von Jahr zu Jahr ändern. Es spielen einfach sehr viele
        verschiedene Faktoren eine Rolle. Deshalb schwankt der Strompreis für
        Haushalte.
      </p>
      <p>
        Falls du dich also schon einmal gefragt hast, warum dein Strompreis oft
        nur für ein Jahr garantiert ist: Das liegt zum Teil daran.
      </p>

      <span>Quellen:</span>
      <br />
      <br />

      <div className="links">
        * Fraunhofer-Institut
        <br />
        <a
          href="https://www.ise.fraunhofer.de/de/presse-und-medien/news/2021/nettostromerzeugung-im-1-quartal-2021-anteil-der-erneuerbaren-energien-im-jahresvergleich-gesunken.html"
          target="_blank"
          rel="noreferrer"
        >{`https://www.ise.fraunhofer.de/de/presse-und-medien/news/2021/nettostromerzeugung-im-1-quartal-2021-anteil-der-erneuerbaren-energien-im-jahresvergleich-gesunken.html`}</a>
      </div>
    </div>
  );
};

export default Article10;
