import React, { ReactElement } from "react";

interface PinterestIconProps {
  fill: string;
}

const PinterestIcon = ({ fill }: PinterestIconProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <path
        fill={fill || "#526DF2"}
        fillRule="evenodd"
        d="M20 0c11.046 0 20 8.954 20 20s-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0zm.311 8.571c-6.266.001-9.597 4.016-9.597 8.393 0 2.03 1.135 4.562 2.95 5.365.519.233.45-.052.896-1.756.035-.142.017-.265-.097-.398C11.867 17.173 13.956 11 19.94 11c8.66 0 7.043 11.983 1.507 11.983-1.427 0-2.49-1.12-2.153-2.506.407-1.65 1.206-3.424 1.206-4.614 0-2.998-4.467-2.553-4.467 1.42 0 1.227.434 2.055.434 2.055s-1.437 5.806-1.704 6.89c-.451 1.837.061 4.81.106 5.066.027.14.186.185.274.07.142-.186 1.88-2.665 2.366-4.456.177-.652.904-3.3.904-3.3.479.865 1.86 1.588 3.331 1.588 4.378 0 7.542-3.847 7.542-8.622-.016-4.577-3.933-8.002-8.975-8.002z"
      />
    </svg>
  );
};

export default PinterestIcon;
