import React from "react";

import icon4 from "../../assets/header_stromee.png";
import iconAuthor from "../../assets/authorPicture.jpg";

import {
  Article1,
  Article2,
  Article3,
  Article4,
  Article5,
  Article6,
  Article7,
  Article8,
  Article9,
  Article10,
  Article11,
  Article12,
  TitleContent1,
  TitleContent2,
  TitleContent3,
  TitleContent4,
  TitleContent5,
  TitleContent6,
  TitleContent7,
  TitleContent8,
  TitleContent9,
  TitleContent10,
  TitleContent11,
  TitleContent12,
} from "./ArticlesData/index";

export const mostPopularPosts = [
  {
    title: "123Unmatched Toner Cartridge Quality 20 Less Than Oem Price",
    icon: icon4,
    label: "Das sind wir",
    categoryName: "about-us",
    authorInfo: "von Harriet Bouvain",
    authorName: "Harriet Bouvain",
    authorDescription:
      "Bei Harriet ging es eigentlich immer schon um Sprache und ums Texten - Warum also nicht das Hobby zum Beruf machen? Und beim Thema Nachhaltigkeit kann sie nun auch endlich ihre angefangen-aber-nie-zuende-geführten-weil-Gründe upcycling Projekte in Blogartikel verpacken. Win-win.",
    content: <Article2 />,
    description: "Hallo, das sind wir - stromee",
    articleId: "123",
  },
  {
    title: "Unmatched Toner Cartridge Quality 20 Less Than Oem Price",
    icon: icon4,
    label: "Das sind wir",
    categoryName: "about-us",
    authorInfo: "von Harriet Bouvain",
    authorName: "Harriet Bouvain",
    content: <Article2 />,
    description: "Hallo, das sind wir - stromee",
    articleId: "124",
  },
  {
    title: "Unmatched Toner Cartridge Quality 20 Less Than Oem Price",
    icon: icon4,
    label: "Das sind wir",
    categoryName: "about-us",
    authorInfo: "von Harriet Bouvain",
    authorName: "Harriet Bouvain",
    content: <Article2 />,
    description: "Hallo, das sind wir - stromee",
    articleId: "125",
  },
];

export const articlesData = [
  {
    title: "Warum sich die Stromanbieter Bonusschlacht nicht auszahlt.",
    titleContent: <TitleContent12 />,
    icon: "../header_blog14.jpg",
    iconPreview: "../preview_header_blog14.jpg",
    label: "Strom 1x1",
    categoryName: "energy",
    authorInfo: "von Harriet Bouvain",
    authorName: "Harriet Bouvain",
    authorDescription:
      "Bei Harriet ging es eigentlich immer schon um Sprache und ums Texten - Warum also nicht das Hobby zum Beruf machen? Und beim Thema Nachhaltigkeit kann sie nun auch endlich ihre angefangen-aber-nie-zuende-geführten-weil-Gründe upcycling Projekte in Blogartikel verpacken. Win-win.",
    authorIcon: iconAuthor,
    content: <Article12 />,
    description:
      "Bei Stromtarifen ohne Bonus Geld sparen - klingt seltsam? Rechnet sich aber. Hier erfährst du warum sich die Bonusschlacht für dich nicht lohnt.",
    articleId: "152",
    views: 50,
    date: "14.09.2021",
    readingTime: "7 min",
    hashtag: null,
    articleTitle: "bonusschlacht-lohnt-nicht",
  },
  {
    title: "Smart Home & Ökostrom: nachhaltig Geld sparen",
    titleContent: <TitleContent11 />,
    icon: "../header_blog13.jpg",
    iconPreview: "../preview_header_blog13.jpg",
    label: "Bewusst & nachhaltig",
    categoryName: "sustainable-life",
    authorInfo: "von Harriet Bouvain",
    authorName: "Harriet Bouvain",
    authorDescription:
      "Bei Harriet ging es eigentlich immer schon um Sprache und ums Texten - Warum also nicht das Hobby zum Beruf machen? Und beim Thema Nachhaltigkeit kann sie nun auch endlich ihre angefangen-aber-nie-zuende-geführten-weil-Gründe upcycling Projekte in Blogartikel verpacken. Win-win.",
    authorIcon: iconAuthor,
    content: <Article11 />,
    description:
      "Hier erfährst du, warum Ökostrom und Smart Home die perfekte Kombi sind und was sich damit so alles sparen lässt.",
    articleId: "151",
    views: 50,
    date: "31.08.2021",
    readingTime: "7 min",
    hashtag: null,
    articleTitle: "smarthome-oekostrom",
  },
  {
    title: "Vom Kraftwerk bis zur Steckdose",
    titleContent: <TitleContent10 />,
    icon: "../header_blog12.jpg",
    iconPreview: "../preview_header_blog12.jpg",
    label: "Strom 1x1",
    categoryName: "energy",
    authorInfo: "von Harriet Bouvain",
    authorName: "Harriet Bouvain",
    authorDescription:
      "Bei Harriet ging es eigentlich immer schon um Sprache und ums Texten - Warum also nicht das Hobby zum Beruf machen? Und beim Thema Nachhaltigkeit kann sie nun auch endlich ihre angefangen-aber-nie-zuende-geführten-weil-Gründe upcycling Projekte in Blogartikel verpacken. Win-win.",
    authorIcon: iconAuthor,
    content: <Article10 />,
    description:
      "Hast du dich auch schonmal gefragt, was eigentlich zwischen Stromerzeugung und Kaffeemaschine einschalten so alles mit der Energie passiert? Dann findest du hier bestimmt die Antworten und auch noch ein paar extra Infos zum Thema Strompreis und Strombörse.",
    articleId: "150",
    views: 50,
    date: "14.07.2021",
    readingTime: "7 min",
    hashtag: null,
    articleTitle: "kraftwerk-zur-steckdose",
  },
  {
    title: "Energieeffizienzklassen 2021",
    titleContent: <TitleContent8 />,
    icon: "../header_blog8.jpg",
    iconPreview: "../preview_header_blog8.jpg",
    label: "Strom 1x1",
    categoryName: "energy",
    authorInfo: "von Harriet Bouvain",
    authorName: "Harriet Bouvain",
    authorDescription:
      "Bei Harriet ging es eigentlich immer schon um Sprache und ums Texten - Warum also nicht das Hobby zum Beruf machen? Und beim Thema Nachhaltigkeit kann sie nun auch endlich ihre angefangen-aber-nie-zuende-geführten-weil-Gründe upcycling Projekte in Blogartikel verpacken. Win-win.",
    authorIcon: iconAuthor,
    content: <Article8 />,
    description:
      "Update bei den Energieeffizienzklassen. Ab März 2021 gibt es ein neues Energielabel. Und es ändert sich eine ganze Menge - was genau? Das erfährst du in unserem Artikel.",
    articleId: "147",
    views: 50,
    date: "12.02.2021",
    readingTime: "7 min",
    hashtag: null,
    articleTitle: "energieeffizienzklassen-2021",
  },
  {
    title: "Bewusst heizen - so sparst du Energie und Geld",
    titleContent: <TitleContent9 />,
    icon: "../header_blog9.jpg",
    iconPreview: "../preview_header_blog9.jpg",
    label: "Bewusst & nachhaltig",
    categoryName: "sustainable-life",
    authorInfo: "von Harriet Bouvain",
    authorName: "Harriet Bouvain",
    authorDescription:
      "Bei Harriet ging es eigentlich immer schon um Sprache und ums Texten - Warum also nicht das Hobby zum Beruf machen? Und beim Thema Nachhaltigkeit kann sie nun auch endlich ihre angefangen-aber-nie-zuende-geführten-weil-Gründe upcycling Projekte in Blogartikel verpacken. Win-win.",
    authorIcon: iconAuthor,
    content: <Article9 />,
    description:
      "Herbst und Winter sind Heizsaison. Aber heizt du denn auch “richtig”? Wie du mit ein paar einfachen Tipps beim Heizen viel Energie - und Geld - sparst, erfährst du hier.",
    articleId: "148",
    views: 50,
    date: "12.02.2021",
    readingTime: "7 min",
    hashtag: null,
    articleTitle: "bewusst-heizen",
  },
  // TODO remove commented code and add this article to the list
  // {
  //   title:
  //     "Selbstgemachte Bienenwachstücher: gut gewachst ist besser frisch gehalten.",
  //   titleContent: <TitleContent7 />,
  //   icon: "../header_blog7.jpg",
  //   iconPreview: "../preview_header_blog7.jpg",
  //   label: "Bewusst & nachhaltig",
  //   categoryName: "sustainable-life",
  //   authorInfo: "von Harriet Bouvain",
  //   authorName: "Harriet Bouvain",
  //   authorDescription:
  //     "Bei Harriet ging es eigentlich immer schon um Sprache und ums Texten - Warum also nicht das Hobby zum Beruf machen? Und beim Thema Nachhaltigkeit kann sie nun auch endlich ihre angefangen-aber-nie-zuende-geführten-weil-Gründe upcycling Projekte in Blogartikel verpacken. Win-win.",
  //   authorIcon: iconAuthor,
  //   content: <Article7 />,
  //   description:
  //     "Frischhaltefolie zum Selbermachen - also fast. Mit unserer einfachen Anleitung stellst du im Handumdrehen Bienenwachstücher her. Einzeln oder stapelweise, ganz wie du willst. Also nichts wie ran ans DIY-Projekt.",
  //   articleId: "146",
  //   views: 50,
  //   date: "12.02.2021",
  //   readingTime: "7 min",
  //   hashtag: null,
  //   articleTitle: "selbstgemachte-bienenwachstücher",
  // },
  {
    title: "Hallo, das sind wir - stromee",
    titleContent: <TitleContent1 />,
    icon: "../header_stromee.jpg",
    iconPreview: "../preview_header_stromee.jpg",
    label: "Das sind wir",
    categoryName: "about-us",
    authorInfo: "von Harriet Bouvain",
    authorName: "Harriet Bouvain",
    authorDescription:
      "Bei Harriet ging es eigentlich immer schon um Sprache und ums Texten - Warum also nicht das Hobby zum Beruf machen? Und beim Thema Nachhaltigkeit kann sie nun auch endlich ihre angefangen-aber-nie-zuende-geführten-weil-Gründe upcycling Projekte in Blogartikel verpacken. Win-win.",
    authorIcon: iconAuthor,
    content: <Article2 />,
    description:
      "Das sind wir, so sehen wir aus und das machen wir hier bei stromee. Lerne uns - und vor allem auch Carlo - näher kennen und erfahre, warum wir machen, was wir eben machen.",
    articleId: "123",
    views: 50,
    date: "10.10.2020",
    readingTime: "7 min",
    hashtag: null,
    articleTitle: "wir-sind-stromee",
  },
  {
    title: "stromee App - Strom wird digital",
    titleContent: <TitleContent2 />,
    icon: "../picture-blog2.jpg",
    iconPreview: "../preview_picture-blog2.jpg",
    label: "Digitale Welt",
    categoryName: "digital-life",
    authorInfo: "von Harriet Bouvain",
    authorName: "Harriet Bouvain",
    authorDescription:
      "Bei Harriet ging es eigentlich immer schon um Sprache und ums Texten - Warum also nicht das Hobby zum Beruf machen? Und beim Thema Nachhaltigkeit kann sie nun auch endlich ihre angefangen-aber-nie-zuende-geführten-weil-Gründe upcycling Projekte in Blogartikel verpacken. Win-win.",
    authorIcon: iconAuthor,
    content: <Article1 />,
    description:
      "Warum gibt es eine stromee App? Braucht Strom sowas überhaupt? Was haben wir uns bei der Entwicklung gedacht? Findet heraus, was alles hinter unserer stromee App steckt.",
    articleId: "135",
    views: 50,
    date: "10.10.2020",
    readingTime: "7 min",
    hashtag: null,
    articleTitle: "stromee-app-strom-wird-digital",
  },
  {
    title: "Ökostrom: Ist “grün” eigentlich gleich “nachhaltig”?",
    titleContent: <TitleContent3 />,
    icon: "../header_strom_nachhaltiger.jpg",
    iconPreview: "../preview_header_strom_nachhaltiger.jpg",
    label: "Strom 1x1",
    categoryName: "energy",
    authorInfo: "von Harriet Bouvain",
    authorName: "Harriet Bouvain",
    authorDescription:
      "Bei Harriet ging es eigentlich immer schon um Sprache und ums Texten - Warum also nicht das Hobby zum Beruf machen? Und beim Thema Nachhaltigkeit kann sie nun auch endlich ihre angefangen-aber-nie-zuende-geführten-weil-Gründe upcycling Projekte in Blogartikel verpacken. Win-win.",
    authorIcon: iconAuthor,
    content: <Article3 />,
    description:
      "Grüner Strom schön und gut, aber ist der auch wirklich nachhaltig? Wir gehen der Frage auf den Grund. Erfahre mehr über erneuerbare Energien und ob “grün” und “öko” immer auch “nachhaltig” bedeutet oder nicht.",
    articleId: "139",
    views: 50,
    date: "10.10.2020",
    readingTime: "7 min",
    hashtag: null,
    articleTitle: "ökostrom-ist-grün-nachhaltig",
  },
  {
    title: "Strom sparen im Home Office",
    titleContent: <TitleContent4 />,
    icon: "../header_home_office.jpg",
    iconPreview: "../preview_header_home_office.jpg",
    label: "Bewusst & nachhaltig",
    categoryName: "sustainable-life",
    authorInfo: "von Harriet Bouvain",
    authorName: "Harriet Bouvain",
    authorDescription:
      "Bei Harriet ging es eigentlich immer schon um Sprache und ums Texten - Warum also nicht das Hobby zum Beruf machen? Und beim Thema Nachhaltigkeit kann sie nun auch endlich ihre angefangen-aber-nie-zuende-geführten-weil-Gründe upcycling Projekte in Blogartikel verpacken. Win-win.",
    authorIcon: iconAuthor,
    articleId: "140",
    content: <Article4 />,
    description:
      "Privatbüro Home Office. Aber wie sieht es da eigentlich mit dem Stromverbrauch aus? Stürzen wir uns in Unkosten, wenn wir von zu Hause aus arbeiten? Oder geht Home Office auch in stromsparend? ",
    views: 50,
    date: "10.10.2020",
    readingTime: "7 min",
    hashtag: "#lohntsichvoll",
    articleTitle: "strom-sparen-home-office",
  },
  {
    title: "Treibhauseffekt und CO2 - die treuen Begleiter des Klimawandels",
    titleContent: <TitleContent5 />,
    icon: "../header_klima.jpg",
    iconPreview: "../preview_header_klima.jpg",
    label: "Bewusst & nachhaltig",
    categoryName: "sustainable-life",
    authorInfo: "von Harriet Bouvain",
    authorName: "Harriet Bouvain",
    authorDescription:
      "Bei Harriet ging es eigentlich immer schon um Sprache und ums Texten - Warum also nicht das Hobby zum Beruf machen? Und beim Thema Nachhaltigkeit kann sie nun auch endlich ihre angefangen-aber-nie-zuende-geführten-weil-Gründe upcycling Projekte in Blogartikel verpacken. Win-win.",
    authorIcon: iconAuthor,
    content: <Article5 />,
    description:
      "Ja, noch ein Artikel zum Klimawandel, das Thema ist präsenter denn je. Klimawandel leicht erklärt: Was steckt eigentlich genau dahinter und was hat CO2 mit der ganzen Sache zu tun? Was ist der Treibhauseffekt, wie entsteht er und wer sind die Verursacher? Spoiler: wir alle haben da unsere Hand im Spiel.",
    articleId: "144",
    views: 50,
    date: "10.10.2020",
    readingTime: "7 min",
    hashtag: null,
    articleTitle: "klimawandel-treibhauseffekt-co2",
  },
  {
    title: "homee & stromee @ IFA 2020",
    titleContent: <TitleContent6 />,
    icon: "../header_finished.jpg",
    iconPreview: "../preview_header_finished.jpg",
    label: "Das sind wir",
    categoryName: "about-us",
    authorInfo: "von Harriet Bouvain",
    authorName: "Harriet Bouvain",
    authorDescription:
      "Bei Harriet ging es eigentlich immer schon um Sprache und ums Texten - Warum also nicht das Hobby zum Beruf machen? Und beim Thema Nachhaltigkeit kann sie nun auch endlich ihre angefangen-aber-nie-zuende-geführten-weil-Gründe upcycling Projekte in Blogartikel verpacken. Win-win.",
    authorIcon: iconAuthor,
    content: <Article6 />,
    description:
      "Die IFA 2020 war diesmal irgendwie anders. Trotzdem konnte stromee am homee Stand zum ersten Mal Messeluft schnuppern.Wenn du wissen möchtest, wie stromee und homee zum IFA-Thema Nachhaltigkeit und CO2 Einsparung beitragen, dann bist du hier genau richtig.",
    articleId: "145",
    views: 50,
    date: "10.10.2020",
    readingTime: "7 min",
    hashtag: null,
    articleTitle: "stromee-auf-der-IFA-2020",
  },
];
export default articlesData;
