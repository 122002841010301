import React from "react";

import diagram from "../../../assets/Article12/bonus-diagramm.png";
import magglass from "../../../assets/Article12/bonus-lupe.png";

import StoreButton from "../../StoreButton";
import styles from "../../Footer/styles.mod.scss";
import { ButtonsWrapper } from "../styled";

const Article12: React.FC = () => {
  return (
    <article>
      <p>
        Besonders auf Vergleichsportalen locken viele Stromanbieter mit hohen
        Boni. Nur wenige Stromanbieter heben sich von der Masse ab und
        verzichten darauf, ein riesiges Rabattpaket auf ihren Tarif zu packen.
        <br />
        <br />
        Stromtarif ganz ohne Bonus - klingt erstmal nicht so attraktiv, ist aber
        für dich als Verbraucher echt praktisch und fair.
      </p>

      <h2>Was genau ist eigentlich ein Bonus?</h2>
      <p>
        Bevor wir dazu kommen, warum ein Stromtarif ohne Bonus richtig super
        ist, vorweg kurz und knapp was genau eigentlich hinter einem Bonustarif
        steckt. Denn Bonus ist nicht gleich Bonus.
        <br />
        <br />
        Manche Anbieter bieten dir einen <strong> Bonus über Addons</strong>,
        also Gratisprodukte, die du bei Abschluss des Tarifs erhältst.
        <br />
        <br />
        Andere Anbieter arbeiten mit “versteckten” Boni: dir wird im 1. Jahr ein
        besonders <strong> günstiger Energie- oder der Grundpreis</strong>{" "}
        geboten.
        <br />
        <br />
        Und dann gibt es natürlich <strong> Cash-Boni</strong>, also
        Bonuszahlungen. Hier haben die Anbieter dann verschiedene Möglichkeiten,
        wann und wie dieser Bonus ausgezahlt oder verrechnet wird:
      </p>
      <ul>
        <li>
          <strong>Ein Neukundenbonus</strong> ist ein Rabatt auf den
          Gesamtpreis. Meistens wird dieser Bonus auf der Jahresrechnung mit
          verrechnet. Du erhältst ihn also meist erst nach 12 Monaten Laufzeit.
        </li>
        <li>
          <strong>Ein Sofortbonus</strong> wird dir im Gegensatz dazu sofort -
          das bedeutet in diesem Fall ca. 4 und 12 Wochen nach Lieferbeginn -
          ausgezahlt.
        </li>
      </ul>

      <h2>Kein Bonus? keine versteckten Kosten!</h2>
      <p>
        Strom ohne Bonus hört sich nach einem schlechteren Deal für dich an.{" "}
        <strong>
          Tatsächlich heißt “kein Bonus” aber vor allem: keine versteckten
          Kosten und 100 % Transparenz bei den Preisen.
        </strong>
        <br />
      </p>
      <div className="floatingImage">
        <img src={magglass} alt="Preislupe" className="floatRight" />
        <p>
          Viele Anbieter werben mit hohen Boni bei ihren Tarifen. Allerdings
          sind dies meisten einmalige Zahlungen, die Gesamtkosten sind also nur
          im 1. Belieferungsjahr niedrig. Die böse Preisüberraschung kommt dann
          im 2. Jahr. Meistens sind die Bonuszahlungen nämlich an zusätzliche
          Bedingungen oder verlängerte Laufzeiten geknüpft. Hier wird dann nach
          dem 1. Jahr ein weitaus höherer Tarif berechnet, der plötzlich doppelt
          so teuer ist wie zu Beginn der Laufzeit. Schlussendlich hast du so
          durch den Bonus kaum etwas gespart.
        </p>
      </div>
      <p>
        Einige Anbieter entscheiden sich deswegen ganz bewusst gegen dieses
        System. Stattdessen legen sie Wert auf einen{" "}
        <strong>
          fairen Preis und Transparenz für ihre Kunden, ohne versteckte Kosten
        </strong>
        . Der Vorteil für dich: du siehst auf den ersten Blick, was du zahlst,
        ohne komplizierte Berechnungen anstellen zu müssen, um versteckte Kosten
        nach Auszahlung des Bonus mit einzuplanen. Übrigens: auch bei stromee
        bleiben wir da fair und transparent. &#128521;
        <br />
        <br />
        <strong>
          Du willst wissen, wie viel du bei einem stromee Tarif sparst? Dann hol
          dir dein persönliches Angebot.
        </strong>
      </p>

      <ButtonsWrapper>
        <StoreButton
          url="https://stromee.de"
          target="_blank"
          text="Angebot erhalten"
          className={styles.footer__storeButton}
        />
      </ButtonsWrapper>

      <h2>Strom ohne Bonus lohnt sich</h2>
      <p>
        Transparenz und faire Preise sind gut und schön, aber du willst als
        Kunde ja trotzdem sparen. Das macht Tarife mit Boni sehr verlockend.
        Aber auch, wenn es vielleicht nicht so aussieht, bei Tarifen ohne Bonus
        sparst du wahrscheinlich mehr. Du brauchst nur ein bisschen
        Durchhaltevermögen.
        <br />
        <br />
        Im 1. Jahr sind Stromtarife mit Bonuszahlungen natürlich wesentlich
        günstiger, als ein Tarif ohne Bonus. Aber schon ab dem 2. Jahr ist der
        Tarifpreis meist wesentlich höher, als im 1. Jahr. Bei Tarifen ohne Boni
        bleiben die Kosten im Gegensatz dazu eher stabil und es gibt keine
        plötzliche krasse Erhöhung des Tarifs.
        <br />
        <br />
        <strong>Hier mal ein anschaulicher Vergleich:</strong>
        <br />
        <br />
        Für Tarif SuperBonus zahlst du im 1. Jahr 630 Euro. Tarif BonusFrei
        kostet dich 708 Euro. Mit dem SuperBonus sparst du also augenscheinlich
        78 Euro.
        <br />
        <br />
        Im 2. Jahr sieht die Situation dann allerdings so aus: SuperBonus kostet
        808 Euro, während BonusFrei weiterhin bei 708 Euro bleibt. Damit zahlst
        du zusammengenommen für den SuperBonus Tarif 1438 Euro. BonusFrei kostet
        dich nur 1416. Das bedeutet: schon im 2. Jahr ist der Tarif ohne Bonus
        günstiger für dich.
      </p>
      <img
        src={diagram}
        alt="akkumulierte Ersparnis im Vergleich zum Bonustarif"
      />
      <p>
        Statt also jährlich den Stromanbieter zu wechseln und dir den ganzen
        Stress anzutun, kannst du auch einfach einen günstigen Stromtarife ohne
        Bonus wählen (zum Beispiel unseren stromee Ökostromtarif). So hast du
        von Anfang an stabile Preise mit langfristiger Preisgarantie und
        außerdem immer den Überblick über deine Kosten. So musst du dich nicht
        auf unschöne Preisüberraschungen gefasst machen.
      </p>

      <br />
      <br />
      <span>Quellen:</span>
      <br />
      <br />

      <div className="links">
        <a
          href="https://www.verbraucherzentrale.de/wissen/energie/preise-tarife-anbieterwechsel/was-sie-bei-bonustarifen-fuer-strom-und-gas-beachten-sollten-6435"
          target="_blank"
          rel="noreferrer"
        >
          {`https://www.verbraucherzentrale.de/wissen/energie/preise-tarife-anbieterwechsel/was-sie-bei-bonustarifen-fuer-strom-und-gas-beachten-sollten-6435`}
        </a>
        <br />
        <br />
        <a
          href="https://www.billig-strom.de/strom-rechner-ohne-bonus.html"
          target="_blank"
          rel="noreferrer"
        >
          {`https://www.billig-strom.de/strom-rechner-ohne-bonus.html`}
        </a>
      </div>
    </article>
  );
};

export default Article12;
