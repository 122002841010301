import React from "react";

import icon16Article5 from "../../../assets/Article5/zusammensetzung-atmosphäre-detailliert.png";
import icon17Article5 from "../../../assets/Article5/treibhaus-emissionen-deutschland.png";
import icon19Article5 from "../../../assets/Article5/einsatz-fossiler-brennstoffe.png";

import StoreButton from "../../StoreButton";
import styles from "../../Footer/styles.mod.scss";
import { ButtonsWrapper, ButtonWrapper } from "../styled";

const Article5: React.FC = () => {
  return (
    <div>
      <p>
        <strong>Klimawandel: ein Diskussionsthema, aktueller denn je</strong>
      </p>
      <p>
        Wirklich jeder hat schon vom Klimawandel gehört. Es ist natürlich kein
        neues Phänomen, aber spätestens die Fridays for Future Bewegung ruft es
        auch wieder ins kollektive Bewusstsein. Jeder hat so seine Meinung zu
        dem Thema, der Klimawandel ist omnipräsent. Aber was jetzt der
        Treibhauseffekt damit zu tun hat, oder gar im Detail ist, und welche
        Rolle dabei das CO<sub>2</sub> spielt - das geht in den Diskussionen
        manchmal doch etwas unter.{" "}
      </p>
      <p>
        Dieser Artikel soll dir ein bisschen Überblick verschaffen. Egal, ob du
        kompletter Newbie auf dem Gebiet bist oder einfach dein Wissen etwas
        auffrischen oder vertiefen willst, hier findest du die nötigen Infos -
        kurz und kompakt.
      </p>
      <h2>Das Klima im Wandel</h2>
      <p>Stürzen wir uns gleich mal in ein komplexes Thema.</p>
      <p>
        Es gibt ja Menschen, die behaupten, den Klimawandel gäbe es nicht -
        beziehungsweise, das Klima habe sich schon immer geändert, das sei alles
        natürlich.
      </p>
      <p>
        Im Laufe der Erdgeschichte gab es immer mal wieder Warmzeiten und
        Eiszeiten, das ist richtig. Aber genauso offensichtlich ist: Als im 19.
        Jahrhundert die Industrielle Revolution einsetzt, steigen parallel die
        CO<sub>2</sub>-Konzentration in der Atmosphäre und die Temperatur.
        Zufall? Wohl eher nicht.{" "}
      </p>
      <p>
        <strong>
          WissenschaftlerInnen sind sich jedenfalls einig, dass der erhöhte CO
          <sub>2</sub>
          Ausstoß und der Temperaturanstieg menschengemacht sind.
        </strong>{" "}
        Wie man dem Wandel jetzt aber entgegentreten soll, darüber liefern sich
        Klimaforschung, Politik, Medien und die sogenannten “Klimaskeptiker”
        eine anhaltende Debatte. Und während da fleißig diskutiert und geforscht
        wird, steigt der CO<sub>2</sub>-Anteil weiter und weiter. Bisher ist
        kein Ende in Sicht.
      </p>
      <p>
        Klimaschwankungen gab es immer und wird es immer geben, keine Frage. Und
        vielleicht wären die Temperaturen in den letzten Jahren auch ohne unser
        Dazutun ein wenig gestiegen. Aber der Klimawandel ist, wenn nicht
        komplett durch den Menschen verursacht, dann doch in großem (und zwar in
        sehr großem) Maße durch unsere CO<sub>2</sub>-Produktion von uns
        begünstigt.{" "}
      </p>
      <p>Und das ist auch nicht wegzudiskutieren.</p>
      <h2>
        So weit, so schlimm. Aber was genau hat CO<sub>2</sub> jetzt mit dem
        Treibhauseffekt zu tun?
      </h2>
      <p>
        <strong>
          CO<sub>2</sub>, also Kohlendioxid, gehört zusammen mit Methan (CH
          <sub>4</sub>), Lachgas (N<sub>2</sub>O) und den sogenannten F-Gasen zu
          den Treibhausgasen.
        </strong>{" "}
        Bezogen auf die gesamte Atmosphäre macht CO<sub>2</sub> mit 0,037 % mit
        Abstand den größten Anteil an Treibhausgasen aus (auch, wenn 0,037 %
        nach wenig klingt). Deshalb wird beim Treibhauseffekt das CO<sub>2</sub>{" "}
        oft auch stellvertretend für das gesamte Gasgemisch verwendet.
      </p>
      <img src={icon16Article5} alt="icon" />
      <p>
        Womit wir schon beim Stichwort wären:
        <br />
        <strong>
          Der Treibhauseffekt entsteht durch die Treibhausgase. Das ist an sich
          erst mal nichts Schlechtes. Im Gegenteil, die Gase sind ja natürlicher
          Bestandteil unserer Atmosphäre. Ohne sie wäre unsere Erde kein
          wirklich angenehmer Lebensraum.{" "}
        </strong>
      </p>
      <p>
        Es läuft - ganz vereinfacht - so ab: Die Sonne strahlt auf die Erde. Ein
        kleiner Teil wird von der Erdoberfläche, dem Wasser oder der Vegetation
        reflektiert und strahlt zurück ins Weltall. Dieser Teil der
        Sonnenenergie ist damit schon mal verloren. Den größten Teil absorbieren
        Land und Wasser aber, wodurch sie sich erwärmen. Diese Wärme strahlen
        sie dann als Infrarotstrahlung zurück in unsere Atmosphäre.{" "}
      </p>
      <p>
        Der springende Punkt dabei: Ohne unsere Treibhausgase würde diese
        abgestrahlte Wärme auf Nimmerwiedersehen ins All verschwinden - und wir
        würden alle (er)frieren.{" "}
      </p>
      <p>
        Aber CO<sub>2</sub> und Co. funktionieren in unserer Atmosphäre zum
        Glück wie ein Spiegel, sie reflektieren also einen Anteil der
        Wärmestrahlen und werfen sie wieder zurück Richtung Erde.{" "}
      </p>
      <h2>Wo liegt jetzt also das Problem?</h2>
      <p>
        Es ist wie es immer ist: zu viel des Guten ist eben leider zu viel.
        <br />{" "}
        <strong>
          Je mehr Treibhausgas in der Atmosphäre, desto besser funktioniert der
          Spiegel - logisch. Es wird also mehr und mehr Wärmestrahlung
          reflektiert und immer weniger strahlt ins All ab. Resultat: Unsere
          Erde erwärmt sich schneller als “normal”.
        </strong>
      </p>
      <p>
        In den letzten 150 Jahren betrug diese Erwärmung übrigens rund 1 Grad*
        Celsius. Etwa ⅔ dieser Erwärmung fallen dabei auf den Zeitraum innerhalb
        der letzten 50 Jahre. Die Temperatur ist also nicht linear, sondern
        exponentiell angestiegen.** Der Anstieg insgesamt hört sich vielleicht
        nicht nach so viel an, könnte man denken, aber schon 1 bis 2 Grad
        Unterschied können verheerende und vielfältige Folgen haben: hierzu
        zählen - und das ist nur eine kleine Auswahl - erheblich mehr Schäden
        durch Überschwemmungen und Stürme, steigende Wasserknappheit und Hunger,
        aber auch gesundheitliche Probleme durch Hitzestress, Unterernährung,
        Infektionen, etc.***
      </p>
      <p>
        Eine höhere Konzentration an Treibhausgasen in der Atmosphäre ist also
        mehr als einfach nur mal ein heißer Sommer.
      </p>
      <p>An dieser Stelle ein kurzer Abstecher: </p>
      <h2>Treibhaus-Emissionen hier in Deutschland.</h2>
      <p>
        Schauen wir uns die Zahlen des Umweltbundesamtes an. Die Zusammensetzung
        unserer Emissionen, also das, was wir an Gasen in die Luft ausstoßen,
        ist wie folgt:
      </p>
      <div className="chartItem">
        <ul>
          <li>
            <span>Kohlendioxid:</span> 88,00 %
          </li>
          <li>
            <span>Methan:</span> 6,13 %{" "}
          </li>
          <li>
            <span>Lachgas:</span> 4,14 %{" "}
          </li>
          <li>
            <span>Rest:</span> 1,73 %{" "}
          </li>
        </ul>
        <img src={icon17Article5} alt="icon" />
      </div>
      <p>
        Hier liegt CO<sub>2</sub> ganz klar vorne. Kein Wunder also, dass
        eigentlich meist von CO<sub>2</sub>-Emissionen die Rede ist, wenn es um
        den Klimawandel geht.
      </p>
      <h2>
        Aber woher kommt das ganze CO<sub>2</sub>, das wir ausstoßen überhaupt?
      </h2>
      <p>
        Ein Großteil steckt in den fossilen Brennstoffen, also Kohle, Erdöl,
        Erdgas. Sie alle sind aus Pflanzen und mikroskopisch kleinen Lebewesen
        entstanden, die vor Millionen Jahren existierten. Pflanzen und Tiere
        nehmen während ihrer Lebenszeit Kohlenstoff (C) aus dem CO<sub>2</sub>{" "}
        in der Luft auf. Dieser Kohlenstoff ist immer noch in den fossilen
        Brennstoffen gespeichert und wird jetzt natürlich beim Verbrennen auf
        einen Schlag freigesetzt. So entstehen große Mengen CO<sub>2</sub>.
      </p>
      <p>Eingesetzt werden die fossilen Brennstoffe weltweit für:</p>
      <div className="chartItem">
        <ul>
          <li>Strom und Wärmeerzeugung (über 40 %)</li>
          <li>Transport (knapp 25 %)</li>
          <li>Industrie (etwas unter 25 %)</li>
          <li>Rest (ca. 10 %)</li>
        </ul>
        <img src={icon19Article5} alt="icon" />
      </div>
      <h2>Und genau hier können wir ansetzen! </h2>
      <p>
        All diese Sektoren (Strom, Transport, Industrie) hängen zusammen und
        sind Stellschrauben, um den CO<sub>2</sub>-Ausstoß herunterzukurbeln. Am
        effektivsten ist das, wenn die Sektoren dabei ineinandergreifen.
      </p>
      <h2>Wie könnte das aussehen?</h2>
      <p>
        Zum Beispiel: im Transportsektor sind Elektroautos im Kommen - die grüne
        Alternative zum Verbrennungsmotor. Aber damit das wirklich ökologisch
        Sinn macht, muss natürlich auch der Strom, mit dem man seinen Wagen
        lädt, grün und nachhaltig sein - Stichwort Energiewende.{" "}
      </p>
      <p>
        <strong>Schon stromee Kunde? Wechsle heute noch zu stromee</strong>
      </p>
      <ButtonsWrapper>
        <StoreButton
          url="https://apps.apple.com/de/app/id1521872083"
          target="_blank"
          text="App Store"
          className={styles.footer__storeButton}
        />
        <StoreButton
          url="https://play.google.com/store/apps/details?id=com.stromee.android"
          target="_blank"
          text="Google Play"
          className={styles.footer__storeButton}
        />
      </ButtonsWrapper>
      <p>
        Wenn wir all diese Kettenglieder miteinander verbinden, können wir den
        Treibhausgas-Ausstoß nachhaltig senken. Und das ist ja schließlich das
        Ziel - das muss es zumindest sein.
      </p>
      <p>
        Deshalb ist auch Nachhaltigkeit im Alltag so wichtig. Es gibt vieles,
        mit dem wir indirekt den CO<sub>2</sub>-Ausstoß fördern: Obst aus
        Übersee kaufen zum Beispiel, das ja schließlich irgendwie über den
        halben Erdball transportiert werden muss. Ebenso Kleidung, die in Asien
        produziert wird. Um den eigenen CO<sub>2</sub>-Fußabdruck zu verringern,
        reichen oft schon ein paar kleine Umstellungen im Alltag. Öfter mal Bus
        und Bahn statt Auto (oder hey - vielleicht tut es auch mal das Rad?),
        lokale Produkte kaufen, einen nachhaltigen Stromanbieter wählen (wir
        wüssten da einen 😉), und und und.
      </p>
      <ButtonWrapper>
        <StoreButton
          url="/#hints"
          target="_blank"
          text="Energiespartipps"
          className={styles.footer__storeButton}
        />
      </ButtonWrapper>
      <p>
        Wie heißt es so schön? Jede Reise beginnt mit einem ersten Schritt. Und
        jeder Einzelne von uns sollte diesen Schritt gehen, am besten gestern!
        Wir müssen jetzt etwas ändern, denn der Klimawandel ist bereits im
        Gange. Nur wenn wir die rasante Erderwärmung stoppen, indem wir radikal
        umdenken und nachhaltiger Energie verbrauchen, können wir unseren
        Kindern und Enkeln einen lebenswerten Planeten hinterlassen.
      </p>
      <span>Quellen</span>
      <br />
      <p>
        <a
          href="https://climate.nasa.gov/news/2841/2018-fourth-warmest-year-in-continued-warming-trend-according-to-nasa-noaa/"
          target="_blank"
          rel="noreferrer"
        >
          *https://climate.nasa.gov/news/2841/2018-fourth-warmest-year-in-continued-warming-trend-according-to-nasa-noaa/
        </a>
      </p>
      <p>
        <a
          href="https://www.umweltbundesamt.de/themen/klima-energie/klimawandel/beobachteter-klimawandel"
          target="_blank"
          rel="noreferrer"
        >
          **https://www.umweltbundesamt.de/themen/klima-energie/klimawandel/beobachteter-klimawandel
        </a>
      </p>
      <p>
        <a
          href="https://www.nabu.de/umwelt-und-ressourcen/klima-und-luft/klimawandel/11420.html"
          target="_blank"
          rel="noreferrer"
        >
          ***https://www.nabu.de/umwelt-und-ressourcen/klima-und-luft/klimawandel/11420.html
        </a>
      </p>
    </div>
  );
};

export default Article5;
