import React from "react";

export { default as Article1 } from "./Article1";
export { default as Article2 } from "./Article2";
export { default as Article3 } from "./Article3";
export { default as Article4 } from "./Article4";
export { default as Article5 } from "./Article5";
export { default as Article6 } from "./Article6";
export { default as Article7 } from "./Article7";
export { default as Article8 } from "./Article8";
export { default as Article9 } from "./Article9";
export { default as Article10 } from "./Article10";
export { default as Article11 } from "./Article11";
export { default as Article12 } from "./Article12";

export const TitleContent1: React.FC = () => (
  <span>Hallo, das sind wir - stromee</span>
);
export const TitleContent2: React.FC = () => (
  <span>stromee App - Strom wird digital</span>
);
export const TitleContent3: React.FC = () => (
  <span>Ökostrom: Ist “grün” eigentlich gleich “nachhaltig”?</span>
);
export const TitleContent4: React.FC = () => (
  <span>Strom sparen im Home Office</span>
);
export const TitleContent5: React.FC = () => (
  <>
    Treibhauseffekt und CO<sub>2</sub>
    <br />{" "}
    <span className="subTitle">- die treuen Begleiter des Klimawandels</span>
  </>
);
export const TitleContent6: React.FC = () => (
  <span>homee & stromee @ IFA 2020</span>
);
export const TitleContent7: React.FC = () => (
  <span>
    Selbstgemachte Bienenwachstücher: gut gewachst ist besser frisch gehalten.
  </span>
);
export const TitleContent8: React.FC = () => (
  <span>Energieeffizienzklassen 2021</span>
);
export const TitleContent9: React.FC = () => (
  <span>Bewusst heizen - so sparst du Energie und Geld</span>
);
export const TitleContent10: React.FC = () => (
  <span>Vom Kraftwerk bis zur Steckdose</span>
);
export const TitleContent11: React.FC = () => (
  <span>Smart Home & Ökostrom: nachhaltig Geld sparen</span>
);
export const TitleContent12: React.FC = () => (
  <span>Warum sich die Stromanbieter Bonusschlacht nicht auszahlt.</span>
);
