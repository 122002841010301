import React from "react";

import image1 from "../../../assets/Article9/01_image.jpg";
import image2 from "../../../assets/Article9/02_image.jpg";
import image3 from "../../../assets/Article9/03_image.jpg";
import image4 from "../../../assets/Article9/04_image.jpg";
import image5 from "../../../assets/Article9/05_image.jpg";
import icon1 from "../../../assets/Article9/01_icon.png";
import icon2 from "../../../assets/Article9/02_icon.png";
import icon3 from "../../../assets/Article9/03_icon.png";
import icon4 from "../../../assets/Article9/04_icon.png";
import icon5 from "../../../assets/Article9/05_icon.png";

const Article9: React.FC = () => {
  return (
    <div>
      <p>
        Besonders im Februar wird es ja gerne noch einmal so richtig kalt. Bevor
        deine Heizung in die Sommerpause geht, wird sie also meist noch ein
        letztes Mal ordentlich beansprucht, um dein Zuhause so richtig gemütlich
        zu machen.
      </p>

      <p>
        Aber heizt du auch “richtig”? Oder verschenkst du unnötig viel Wärme
        (und damit auch Geld!)?
      </p>

      <p>
        Und lohnt es sich für dich überhaupt, sich darüber Gedanken zu machen?
      </p>

      <p>
        Ein Blick auf das Diagramm zeigt: ⅔ der Energie im Privathaushalt wird
        fürs Heizen aufgewendet.{" "}
        <strong>
          Tatsächlich macht Heizung zusammen mit Warmwasser den größten Teil der
          jährlichen Nebenkosten aus.
        </strong>{" "}
        Es ist also durchaus sinnvoll, dir deine Heizgewohnheiten mal genauer
        anzuschauen. Eventuell lässt sich ja an der einen oder anderen Stelle
        noch ein bisschen was einsparen. 😉
      </p>

      <img src={image1} alt="icon" />
      <div className="links">
        <span>
          Quelle:{" "}
          <a
            href="https://www.umweltbundesamt.de/daten/private-haushalte-konsum/wohnen/energieverbrauch-privater-haushalte#endenergieverbrauch-der-privaten-haushalte"
            target="_blank"
            rel="noreferrer"
          >
            https://www.umweltbundesamt.de/daten/private-haushalte-konsum/wohnen/energieverbrauch-privater-haushalte#endenergieverbrauch-der-privaten-haushalte
          </a>{" "}
        </span>
      </div>
      <br />

      <p>
        <strong>Einheizen - aber “richtig”!</strong>
      </p>
      <p>
        Heizen ist nicht gleich heizen - schon mit ein paar kleinen Änderungen
        kannst du groß sparen. Dazu hier unsere Top 5 Tipps.
      </p>

      <div className="article9ListTitle">
        <img className="article9ListTitle_img" src={icon1} alt="icon" />
        <p className="article9ListTitle_text">
          <strong>Nummer 1: Am Thermostat drehen.</strong>
        </p>
      </div>
      <p>
        Die Zahlen auf dem Heizungsthermostat sind ja nicht nur zur Zierde,
        sondern zeigen an, wie warm es im Zimmer wird.
      </p>

      <img src={image2} alt="icon" />
      <div className="links">
        <span>
          Quelle:{" "}
          <a
            href="https://www.effizienzhaus-online.de/thermostat-das-bedeuten-die-zahlen/"
            target="_blank"
            rel="noreferrer"
          >
            https://www.effizienzhaus-online.de/thermostat-das-bedeuten-die-zahlen/
          </a>
        </span>
      </div>
      <br />

      <p>
        Bedeutet also:{" "}
        <strong>
          die Heizung heizt nicht schneller, wenn man den Thermostat voll
          aufdreht
        </strong>
        . Sie heizt nur länger, weil eine höhere Raumtemperatur erreicht werden
        muss. <br />
        Also am besten den Thermostat direkt auf Wohlfühltemperatur einstellen.
      </p>

      <p>
        Außerdem: <strong>Jedes Grad weniger spart Energie und Kosten</strong>.
        Es muss ja nicht die ganze Wohnung auf vollen Touren beheizt werden. Im
        Schlafzimmer sind 17 Grad oder sogar weniger viel angenehmer. Und falls
        du nachts doch frieren solltest hilft hier eine Wärmflasche meist besser
        als ein beheiztes Zimmer - günstiger ist es auf jeden Fall.
      </p>

      <p>
        Du sollst natürlich bei dir Zuhause nicht frieren, aber{" "}
        <strong>
          die Heizung nur um 1 Grad runterschrauben spart bereits 6 % Energie
        </strong>{" "}
        und dein Körper passt sich der neuen Raumtemperatur ohne Probleme an.
      </p>
      <br />

      <div className="article9ListTitle">
        <img className="article9ListTitle_img" src={icon2} alt="icon" />
        <p className="article9ListTitle_text">
          <strong>Nummer 2: Stichwort Heizwärme</strong>
        </p>
      </div>
      <p>
        Vor allem durch Fenster entweicht viel Heizwärme, weil diese natürlich
        nicht die gleiche Isolationswirkung haben wie Wände. Dem kannst du aber
        zumindest über Nacht gut entgegenwirken: einfach{" "}
        <strong>
          mit Sonnenuntergang die Rollläden runterlassen, das reduziert den
          Wärmeverlust um bis zu 20 %
        </strong>
        . Und zusätzlich Vorhänge zuziehen spart dann nochmal ein paar Prozente.
      </p>

      <p>
        Auch die <strong>Zimmertüren</strong> solltest du möglichst{" "}
        <strong>abdichten</strong>, damit die Wärme im Raum bleibt. Da reicht es
        schon, ein Stück Stoff vor den Türspalt zu legen.
      </p>
      <br />

      <div className="article9ListTitle">
        <img className="article9ListTitle_img" src={icon3} alt="icon" />
        <p className="article9ListTitle_text">
          <strong>Nummer 3: Gewusst wie lüften</strong>
        </p>
      </div>
      <p>
        Richtig heizen ohne richtiges Lüften bringt wenig.{" "}
        <strong>Stoßlüften</strong> heißt hier das Zauberwort - und zwar{" "}
        <strong>mehrmals am Tag</strong>. Fenster dauerhaft auf Kippe stellen
        kühlt nur die Wände aus, sorgt aber nicht wirklich für viel
        Luftaustausch.
      </p>
      <p>
        Stoßlüften spart übrigens nicht nur Energie, sondern beugt auch
        Schimmelbildung vor.
      </p>

      <div className="article9ListTitle">
        <img className="article9ListTitle_img" src={icon4} alt="icon" />
        <p className="article9ListTitle_text">
          <strong>Nummer 4: Luft ablassen</strong>
        </p>
      </div>
      <div className="article9Image">
        <p className="">
          Du solltest deine <strong>Heizung regelmäßig entlüften</strong>, sonst
          steigt das warme Wasser nicht in den Heizkörper.
        </p>
        <img className="article9Image_image" src={image3} alt="icon" />
      </div>

      <div className="article9ListTitle">
        <img className="article9ListTitle_img" src={icon5} alt="icon" />
        <p className="article9ListTitle_text">
          <strong>Nummer 5: Intelligent heizen</strong>
        </p>
      </div>
      <p>
        Bedeutet, dass du den{" "}
        <strong>Heizthermostat nach Raum und Tageszeit anpasst</strong>. Nicht
        jeder Raum braucht dieselbe Temperatur: im Bad kann es gern etwas
        kuscheliger sein, im Schlafzimmer sollte es eher kühl bleiben. Und in
        Räumen, in denen du dich nicht ständig aufhältst, wie etwa der Küche,
        die Heizung nach Bedarf an- und abdrehen.
      </p>

      <p>
        <img className="article9_img2" src={image4} alt="icon" />
        Und dann natürlich die <strong>Nachtabsenkung</strong> nicht vergessen.
        Während am Tag der Thermostat meist auf 2 bis 3 steht, reicht nachts die
        Einstellung 1 bis 2 oder du schaltest die Heizung ganz ab.
      </p>

      <p>
        Übrigens: viele Thermostate haben die entsprechende Stufe mit einem
        Mondsymbol gekennzeichnet.
      </p>

      <p>
        Das ständige Auf- und Abdrehen kannst du natürlich täglich per Hand
        erledigen. Bequemer ist es allerdings, wenn du deine Heizkörper
        automatisch steuerst - mit einer{" "}
        <strong>intelligenten Schaltung</strong>. <br />
      </p>

      <p>
        <img className="article9_img2" src={image5} alt="icon" />
        Mit einer <strong>Anbindung an eine Smart Home Zentrale</strong> kannst
        du so für jeden Raum einen eigenen Heizplan erstellen und sogar
        festlegen, dass die Heizung automatisch abschaltet, sobald du ein
        Fenster öffnest oder die Außentemperatur über eine bestimmte Gradzahl
        steigt. Energie kannst du so fast von selbst sparen.
      </p>

      <p>
        Wie du mit unserer Smart Home Zentrale homee ganz intelligent solche
        individuellen Heizpläne erstellen kannst, erfährst du{" "}
        <a href="https://hom.ee/" target="_blank" rel="noreferrer">
          bei unseren Smart Home Kollegen
        </a>
        . Schau doch mal bei homee vorbei.
      </p>
    </div>
  );
};

export default Article9;
