import React from "react";

import icon20Article6 from "../../../assets/Article6/besucher-talk.jpg";
import icon21Article6 from "../../../assets/Article6/halle-leer.jpg";
import icon23Article6 from "../../../assets/Article6/homee-stand-ifa.jpg";
import icon24Article6 from "../../../assets/Article6/ifa_vortrag.png";
import icon25Article6 from "../../../assets/Article6/stand-aufbau.jpg";

const Article6: React.FC = () => {
  return (
    <div>
      <p>
        Dieses Jahr war die IFA mal ganz anders - hauptsächlich natürlich wegen
        Corona. Aber auch, weil die Entscheidung teilzunehmen erst in letzter
        Minute getroffen wurde.{" "}
      </p>
      <p>
        Freitag, 28.08.2020 um 22:07 Uhr fiel bei uns der Startschuss. Wir
        hatten also ab Montag ganze 3 Tage zur Vorbereitung auf die IFA 2020. Da
        knapp die Hälfte unseres Berliner Büros zudem noch im Urlaub weilte, war
        ganz schönes Organisationstalent gefragt. Jeder packte mit an und half,
        eine Messe zu organisieren, für die sonst mitunter Wochen an
        Vorbereitung nötig ist. In kürzester Zeit wurden Banner gestaltet, das
        Messestandsinventar zusammengesucht, Anzeigen designt,
        Marketingkampagnen aufgesetzt, Flyer gedruckt und ein kompletter Vortrag
        erstellt.
      </p>
      <img src={icon25Article6} alt="icon" />
      <span>Alles bereit für den Standaufbau</span>
      <h2>
        5 Stunden später sah es dann schon richtig nach was aus - der Aufbautag
        war also schon mal erfolgreich, die IFA konnte starten.
      </h2>
      <img src={icon23Article6} alt="icon" />
      <p>
        Donnerstag, 10 Uhr morgens, die Messe wurde eröffnet. Das Essen stand
        bereit, wir waren alle pünktlich am Stand und warteten auf die Kunden.
        Und warteten. Und warteten.{" "}
      </p>
      <img src={icon21Article6} alt="icon" />
      <p>
        Wenn man die Besucheranstürme aus den vorherigen Jahren gewohnt ist,
        lief es gefühlt diesmal etwas schleppend an. Diese Messe in ihrer
        “Special Edition” fand für Endkunden hauptsächlich digital statt. Vor
        Ort erlaubt war nur Fachpublikum und Reseller. - Es gab also keine
        Laufkundschaft, die als Pulk über den Stand herfällt. PS: ja, damit seid
        ihr gemeint, liebe Community ;)
      </p>
      <img src={icon20Article6} alt="icon" />
      <p>
        <strong>
          Für uns war die IFA dennoch ein voller Erfolg, es wurde sehr viel
          Networking betrieben und wir haben viele interessante Gespräche mit
          potentiellen Partnern geführt.{" "}
        </strong>
      </p>
      <p>
        Neben den Big Playern waren hauptsächlich junge Start Ups vor Ort. Unser
        Lieblingsstand: der smarte Rollkoffer - folgt dir auf Schritt und Tritt.
        Gut, wie erfolgreich das jetzt in einem überfüllten Flughafenhalle
        funktioniert, wissen wir nicht, aber: ein Koffer, der dir
        hinterherrollt! Muss man da mehr zu sagen?
      </p>
      <p>
        Aber genug geschwärmt! ;) An dieser Stelle ein bisschen Hintergrund zur
        IFA.
      </p>
      <p>
        <strong>
          Die IFA ist eine der ältesten Industriemessen Deutschlands - es gibt
          sie schon seit 1924!
        </strong>{" "}
        Hier werden dem breiten Publikum Neuheiten aus dem Bereich
        Unterhaltungs- und Gebrauchselektronik vorgestellt.
      </p>
      <p>
        <strong>
          Dieses Jahr stand die Messe ganz unter dem Thema Nachhaltigkeit und CO
          <sub>2</sub> Einsparung.
        </strong>{" "}
        Immerhin verbraucht jeder von uns jährlich über 11 Tonnen CO<sub>2</sub>{" "}
        (kurz zum Vergleich: um eine Tonne CO<sub>2</sub> abzubauen, muss ein
        Baum ca. 80 Jahre lang wachsen)!
      </p>
      <p>
        Dazu hat auch unser Geschäftsführer Mario Weißensteiner auf der IFA
        einen Vortrag über CO<sub>2</sub> Einsparung und Nachhaltigkeit im Smart
        Home Bereich gehalten. Den Vortrag findest du auf dem YouTube Channel
        von{" "}
        <a
          href="https://www.youtube.com/channel/UCxqLHqCIuLhSM-inxPW9e7A/videos%20%20Video"
          target="_blank"
          rel="noreferrer"
        >
          SmartHome TV
        </a>
        .
      </p>
      <img src={icon24Article6} alt="icon" />
      <p>
        Das Thema Nachhaltigkeit ist uns bei der{" "}
        <a href="https://hom.ee/de/" target="_blank" rel="noreferrer">
          homee
        </a>{" "}
        GmbH sehr wichtig. Das fängt bei homee schon bei der Produktion an -
        made in Germany - also ganz regional. Durch unser offenes System können
        wir außerdem sehr viele unterschiedliche Produkte einbinden, um den CO
        <sub>2</sub> Verbrauch durch intelligentes Schalten der Geräte zu
        verringern. Denn mit jeder eingesparten kWh, die Dank smarter
        Thermostate, Jalousien, Lampen und vieler anderer Produkte nicht für
        überflüssiges Heizen, Kühlen, Licht, etc. verbraucht wird, sparst du CO
        <sub>2</sub>. So kannst du viel energieeffizienter und nachhaltiger
        leben und deinen CO<sub>2</sub> Verbrauch stark reduzieren.
      </p>
      <p>
        Und mit unserer neuen Business Unit{" "}
        <a href="https://www.stromee.de/" target="_blank" rel="noreferrer">
          stromee
        </a>{" "}
        wird’s jetzt auch beim Strom zu 100 % nachhaltig. Als digitaler und
        innovativer Stromanbieter verzichten wir bei stromee komplett auf Papier
        - sind also zu 100 % digital.{" "}
      </p>
      <p>
        Und: unser Strom wird aus reiner Wasserkraft gewonnen - 100 %
        erneuerbare Energie.
      </p>
      <p>
        Wir möchten außerdem mehr Energieeffizienz in deinen Stromhaushalt
        bringen. Mit unserem Ökostrom zum Einkaufspreis verdienen wir nicht an
        deinem Verbrauch, sondern unterstützen dich beim Stromsparen. Denn der
        nachhaltigste - und energieeffizienteste - Strom ist immer noch der, den
        du gar nicht erst verbrauchst.
      </p>
      <p>
        Das Thema der IFA 2020 passte also perfekt auf homee und stromee und wir
        hoffen, dass es durch die Messe noch weiter an Aufmerksamkeit gewinnt
        und wir alle dadurch noch ein Stück nachhaltiger leben und auf unseren
        CO<sub>2</sub> Verbrauch achten.{" "}
      </p>
      <p>
        <strong>
          Wie sieht’s denn übrigens bei dir aus? Weißt du wie groß dein CO
          <sub>2</sub> Fußabdruck ist?{" "}
        </strong>
      </p>
    </div>
  );
};

export default Article6;
