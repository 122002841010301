import React from "react";

import icon14Article4 from "../../../assets/Article4/money.jpg";
import icon15Article4 from "../../../assets/Article4/stromverbrauch_haushalt.png";

import StoreButton from "../../StoreButton";
import styles from "../../Footer/styles.mod.scss";
import { ButtonsWrapper, ButtonWrapper } from "../styled";

const Article4: React.FC = () => {
  return (
    <div>
      <p>
        Morgens kurz ins Bad, Frühstück (sprich: Kaffee) und ab ins Büro - 8
        Stunden vorspulen - abends nach Hause, essen, fernsehen, relaxen,
        schlafen. Erinnerst du dich noch? So ging Arbeiten vor Corona. Jetzt ist
        bei vielen stattdessen oft Home Office angesagt.
      </p>
      <p>
        Mittlerweile sind wir da auch schon richtige Profis geworden, Stichwort
        Haushalt elegant in die Arbeitszeit integrieren. Wäsche falten etwa
        eignet sich hervorragend zum gedanklichen Vorverfassen von Texten oder
        E-Mails.
      </p>
      <p>
        Aber eine wichtige Frage stellt sich natürlich jedem Home Officler: wie
        ist das jetzt eigentlich mit dem Stromverbrauch?
      </p>
      <p>
        Denn Fakt ist, mehr im Haus sein heißt, dass du dort mehr kochst,
        arbeitest, putzt, chillst, kreativ bist - und das meist mit
        elektronischen Hilfsmitteln.
      </p>
      <h2>Steigen da die Stromkosten nicht in astronomische Höhen?</h2>
      <div className="imageItem">
        <img src={icon14Article4} alt="icon" />
        <div>
          <p>Ganz so schlimm ist es zum Glück nicht.</p>
          <p>
            Laptops und Smartphones sind schon gut auf temporären Einsatz ohne
            Stromkabel ausgelegt - sprich relativ effizient was ihren
            Energieverbrauch angeht. Pro Arbeitstag fallen beim Laptop im
            Durchschnitt 15 Cent an, beim Smartphone sogar nur 0,5 Cent. Teurer
            ist da schon der stationäre PC (ca. 50 Cent), vor allem, wenn noch
            externe Festplatten, Drucker oder ähnliches mit im Spiel sind.
          </p>
        </div>
      </div>
      <p>
        Aber auch Kleinvieh macht ja bekanntlich Mist. Und Laptop alleine ist
        auch nicht alles. So musst du zum Beispiel im Home Office ja auch etwas
        essen und dafür werden dann schnell Herd oder Ofen nötig (hier kostet
        eine Stunde Betrieb bis zu 50 Cent). Das läppert sich dann schon.{" "}
      </p>
      <p>
        Ganz konkret: eine warme Mahlzeit mehr pro Tag (nehmen wir hier mal die
        50 Cent) plus täglicher Stromverbrauch durch Arbeiten am Laptop (15
        Cent), macht nach Adam Riese... 65 mal 22 Arbeitstage... 1 430 Cent,
        also 14,30 Euro pro Monat. Und: im Home Office bleiben wir auch meist
        länger auf, also kommen da auch noch täglich ein paar Cent für
        länger-Licht-oder-Fernseher-anlassen dazu.
      </p>
      <p>
        Gut, das sind jetzt immer noch keine Unsummen, lohnt es sich da
        überhaupt, sich Gedanken über das Energiesparen zu machen, fragst du
        dich vielleicht.{" "}
        <strong>Aus Sicht der Nachhaltigkeit - definitiv!</strong>
      </p>
      <p>
        <strong>
          Schon stromee Kunde? Wechsle heute noch zum nachhaltigen Strom
        </strong>
      </p>
      <ButtonsWrapper>
        <StoreButton
          url="https://apps.apple.com/de/app/id1521872083"
          target="_blank"
          text="App Store"
          className={styles.footer__storeButton}
        />
        <StoreButton
          url="https://play.google.com/store/apps/details?id=com.stromee.android"
          target="_blank"
          text="Google Play"
          className={styles.footer__storeButton}
        />
      </ButtonsWrapper>
      <p>
        Aber auch finanziell bringt es dir was, denn die meisten von uns
        verbrauchen nicht nur im Home Office, sondern generell mehr Strom als
        nötig. Zu diesen ca. 2,60 Euro “Home Office Kosten” gesellen sich dann
        also noch ein paar Euro mehr. Laut co<sub>2</sub> online kommen im
        Haushalt so jährlich rund 230 Euro an Stromkosten zusammen, die durch
        effizienteren Stromverbrauch eingespart werden könnten. Und das klingt
        dann schon ganz anders.{" "}
      </p>
      <p>
        <strong>
          Wo wir jetzt also sowieso mehr Zeit als sonst zu Hause verbringen, ist
          das doch der perfekte Zeitpunkt, um sich ein effizienteres
          Stromverbrauchsverhalten anzueignen.
        </strong>
      </p>
      <h2>Aber wo anfangen?</h2>
      <p>
        Dazu musst du natürlich erstmal wissen, was wie wo wieviel Strom
        verbraucht, bzw. wo du unnötig Strom verbrauchst.
      </p>
      <p>
        Nehmen wir dazu mal einen durchschnittlichen Werktag im Home Office:
      </p>
      <p>
        No Brainer ist der Laptop, der PC oder was du eben an Elektronik für die
        Arbeit nutzt (und nach Feierabend läuft ja mindestens eines der Geräte
        zur Unterhaltung nochmal ein paar Stunden). Insgesamt summieren sich da
        27 % deines häuslichen Stromverbrauchs durch Unterhaltungs- und
        Informationsgeräte.
      </p>
      <p>
        Und ist bei dir die Sauberkeit deiner Wohnung auch umgekehrt
        proportional dazu, wie beschäftigt du bist? Sind wir doch mal ehrlich:
        es war schon zu Studienzeiten so und hat sich auch bis heute nicht
        geändert: je mehr man zu tun hat, desto sauberer ist die Wohnung - der
        Haushalt schmeißt sich praktisch dank Prokrastination. Insgesamt tragen
        Waschen und Trocknen 13 %, sonstige Tätigkeiten (zum Beispiel dem White
        Noise des Staubsaugers lauschen) sogar 16 % zum Stromverbrauch bei.
      </p>
      <p>
        Durch das ganze Geputze ist es auch schon Mittag. Im Home Office
        bekommen Herd, Ofen oder Mikrowelle dann ihr tägliches Workout. Das
        braucht schon einiges an Energie, 11 % insgesamt.{" "}
      </p>
      <p>
        Und wer kocht, muss auch spülen. Dahinter verbergen sich dann weitere 7
        % deines Verbrauchs.
      </p>
      <p>
        Du hast sicher fleißig mitgerechnet: wir liegen knapp bei ⅔ deines
        Gesamtverbrauchs. Der Rest entsteht durch Beleuchtung (9 %) und vor
        allem durch Kühlen und Frieren: dein Kühlschrank verbraucht einiges an
        Strom (17 %) - er läuft ja schließlich rund um die Uhr. Und das
        natürlich ganz unabhängig vom Home Office.
      </p>
      <img src={icon15Article4} alt="icon" />
      <span>
        So sieht eine typische Verteilung des Stromverbrauchs im Privathaushalt
        aus
      </span>
      <h2>So geht Strom effizient</h2>
      <p>
        Arbeiten, Kochen, waschen...nun, muss ja alles gemacht werden. Kann man
        denn da überhaupt sparen? Ja, das geht!
      </p>
      <ul>
        <li>
          Bei Fernseher, Laptop und Co. kannst du zum Beispiel direkt den
          Stand-by-Modus überspringen: einfach die Geräte komplett ausschalten.
        </li>
        <li>
          Und wenn du zum Arbeiten statt PC deinen Laptop nimmst, sparst du
          täglich rund 35 Cent - hast du dir ja sicher oben schon selbst
          ausgerechnet.
        </li>
        <li>
          Deine Meditation zu den sanften Klängen der Waschmaschine soll dein
          Home Office natürlich nicht missen, aber eine vollgepackte Maschine
          bei 40° oder sogar 30° (Kochwäsche mal ausgenommen) spart dir auch gut
          Strom (viele Maschinen haben dazu extra ein Eco-Programm).
        </li>
        <li>
          Auch Backen und Kochen muss sein, geht aber auch in effizient: zum
          Beispiel bietet sich zum Wasser kochen - wer hätte es gedacht - der
          Wasserkocher an. Und zwar nicht nur für Tee oder Kaffee, sondern halt
          auch für Nudeln und Co. (nur bitte nicht gleich die Nudeln mit in den
          Wasserkocher packen)
        </li>
        <li>
          Nach dem Essen ist vor dem Spülen. Wer jetzt direkt zu Schwamm und
          Spülmittel greifen möchte, dem sei gesagt: nein, per Hand spülen spart
          nicht mehr Wasser und Strom als der Geschirrspüler. Im Gegenteil: mit
          der Maschine sparst du rund 130 Euro pro Jahr (und der Wasserverbrauch
          ist auch niedriger). Sie muss allerdings gut gefüllt sein, also ruhig
          nur jeden 2. Tag anschmeißen.
        </li>
      </ul>
      <p>
        Das ist natürlich keine vollständige Liste, es gibt bei dir sicher noch
        jede Menge anderer Möglichkeiten, effektiv Strom zu sparen. Nimm es
        einfach als Denkanstoß. Und schau immer mal wieder bei uns vorbei, wir
        geben dir gerne regelmäßig weitere Tipps.
      </p>
      <ButtonWrapper>
        <StoreButton
          url="/#hints"
          target="_blank"
          text="Energiespartipps"
          className={styles.footer__storeButton}
        />
      </ButtonWrapper>
      <p>
        Um nochmal aufs Thema zurückzukommen:{" "}
        <strong>
          ja, beim Home Office steigt der private Stromverbrauch. Aber es lässt
          sich genauso auch an vielen Ecken und Enden etwas sparen.
        </strong>{" "}
        Deshalb kannst du mit ein bisschen Umstellung deiner Gewohnheiten
        bestimmt mehr an Ersparnis rausholen, als du an zusätzlichen Stromkosten
        fürs Home Office reinsteckst.
      </p>
      <p>
        <strong>#lohntsichvoll</strong>
      </p>
    </div>
  );
};

export default Article4;
