import React, { ReactElement } from "react";

interface FbIconProps {
  fill: string;
}

const FbIcon = ({ fill }: FbIconProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <path
        fill={fill || "#526DF2"}
        fillRule="evenodd"
        d="M20 0c11.046 0 20 8.954 20 20s-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0zm2.675 8.571c-3.009 0-5.07 1.893-5.07 5.37v3.202h-3.32v4.063h3.32v10.223h4.07V21.207h3.186l.506-4.063h-3.692v-2.8c0-1.174.317-1.977 1.953-1.977h2.086V8.732c-.36-.05-1.598-.16-3.04-.16z"
      />
    </svg>
  );
};

export default FbIcon;
