import React, { ReactElement } from "react";

interface LinkedinIconProps {
  fill: string;
}

const LinkedinIcon = ({ fill }: LinkedinIconProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <path
        fill={fill || "#526DF2"}
        fillRule="evenodd"
        d="M20 0c11.046 0 20 8.954 20 20s-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0zm5.773 14.286c-2.006 0-3.352 1.097-3.901 2.137h-.058v-1.805h-3.957v13.238h4.12V21.134c.023-1.665.397-3.228 2.472-3.228 2.047 0 2.14 1.849 2.145 3.36v6.591h4.12v-7.494c-.033-3.45-.855-6.077-4.94-6.077zM15 15h-4.286v12.857H15V15zm-1.786-7.143c-1.38 0-2.5 1.115-2.5 2.488 0 1.374 1.12 2.512 2.5 2.512s2.5-1.138 2.5-2.512c0-1.373-1.12-2.488-2.5-2.488z"
      />
    </svg>
  );
};

export default LinkedinIcon;
