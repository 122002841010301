import React, { ReactElement } from "react";

interface LinkIconProps {
  fill: string;
}

const LinkIcon = ({ fill }: LinkIconProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill={fill || "#526DF2"}
          d="M20 0c11.046 0 20 8.954 20 20s-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0zm-6.368 16.758l-1.602 1.601c-1.74 1.712-2.428 4.226-1.803 6.586.625 2.36 2.468 4.203 4.828 4.828 2.36.625 4.874-.063 6.586-1.803l1.601-1.602-1.601-1.601-1.602 1.601c-1.144 1.145-2.813 1.592-4.376 1.173-1.564-.419-2.785-1.64-3.204-3.204-.419-1.563.028-3.232 1.173-4.376l1.601-1.602-1.601-1.601zm9.61-1.603l-8.009 8.009 1.603 1.602 8.01-8.008-1.604-1.603zm4.767-3.164c-2.638-2.64-6.911-2.657-9.571-.04l-1.602 1.602 1.602 1.602 1.601-1.602c1.145-1.144 2.813-1.591 4.377-1.172 1.563.418 2.785 1.64 3.203 3.203.42 1.564-.028 3.232-1.172 4.377l-1.602 1.601 1.602 1.602 1.601-1.602c2.618-2.66 2.6-6.933-.039-9.571z"
        />
      </g>
    </svg>
  );
};

export default LinkIcon;
