import React from "react";

import icon8Article3 from "../../../assets/Article3/icon-biomasse-braune.jpg";
import icon9Article3 from "../../../assets/Article3/icon-geothermie.jpg";
import icon10Article3 from "../../../assets/Article3/icon-solarenergie.jpg";
import icon11Article3 from "../../../assets/Article3/icon-wasserkraft-wellig.jpg";
import icon12Article3 from "../../../assets/Article3/icon-windkraft.jpg";

import StoreButton from "../../StoreButton";
import styles from "../../Footer/styles.mod.scss";
import { ButtonsWrapper } from "../styled";

const Article3: React.FC = () => {
  return (
    <div>
      <p>
        <i>Ökostrom</i>
      </p>
      <p>
        <i>Naturstrom</i>
      </p>
      <p>
        <i>Grüner Strom</i>
      </p>
      <p>
        <i>Nachhaltiger Strom</i>
      </p>
      <p>
        Jeder hat zumindest einen dieser Begriffe schon mal irgendwo gehört,
        gelesen oder sogar selbst darüber diskutiert. Aber was genau steckt
        eigentlich hinter diesen Bezeichnungen? Und ist grüner Strom denn gleich
        Ökostrom gleich nachhaltiger Strom?
      </p>
      <p>
        Nein, leider nicht. Das wäre ja auch zu einfach. Begriffe wie “grüner
        Strom”, “Naturstrom” oder “Ökostrom” bezeichnen zwar dasselbe, aber
        nachhaltig oder nicht, das steht wieder auf einem anderen Blatt.
      </p>
      <p>
        <strong>Schon stromee Kunde? Wechsle heute noch zu stromee</strong>
      </p>
      <ButtonsWrapper>
        <StoreButton
          url="https://apps.apple.com/de/app/id1521872083"
          target="_blank"
          text="App Store"
          className={styles.footer__storeButton}
        />
        <StoreButton
          url="https://play.google.com/store/apps/details?id=com.stromee.android"
          target="_blank"
          text="Google Play"
          className={styles.footer__storeButton}
        />
      </ButtonsWrapper>
      <h2>Was ist eigentlich grüner Strom?</h2>
      <p>
        Da wird es gleich schon leicht kompliziert. Die Begriffe “grüner Strom”,
        “Naturstrom”, “Ökostrom” oder ähnliche Synonyme sind nämlich nicht
        geschützt, haben also keine gesetzlich verankerte Definition. Allgemein
        versteht man darunter aber bei allen elektrische Energie aus
        erneuerbaren Energiequellen. Erneuerbar heißt, der Strom wird aus Wind-
        oder Wasserkraft, Photovoltaik, Biomasse oder Geothermie erzeugt.
      </p>
      <p>Womit wir auch direkt beim nächsten Punkt wären:</p>
      <h2>Erneuerbare Energien und wie daraus Strom wird</h2>
      <div className="imagesItems">
        <div>
          <img src={icon12Article3} />
          <p>
            Wind ist Luft, die sich bewegt. <strong>Windkraft</strong> ist also
            nichts anderes als Bewegungsenergie (kinetische Energie). Sie dreht
            ein Windrad, mit dem ein Generator angetrieben wird. Der Generator
            produziert dann die elektrische Energie.
          </p>
        </div>
        <div>
          <img src={icon11Article3} alt="icon" />
          <p>
            Auch <strong>Wasserkraft</strong> ist Bewegungsenergie. Ein
            Wasserkraftwerk ist im Prinzip wie ein Mühlrad, nur dass es keinen
            Mahlstein antreibt. Stattdessen setzt das Wasser Turbinen in
            Bewegung, die mit einem Generator verbunden sind. Der Generator
            übersetzt diese Bewegungsenergie in elektrischen Strom.
          </p>
        </div>
        <div>
          <img src={icon10Article3} alt="icon" />
          <p>
            <strong>Photovoltaikanlagen</strong> sind Solarzellen. Solarzellen
            bestehen aus sogenannten Halbleiter-Stoffen (z. B. Silizium). Diese
            Stoffe können Licht in Strom umwandeln. Ganz vereinfacht
            funktioniert das so: Sonnenlicht besteht aus kleinen Energiepaketen,
            den Photonen. Wenn sie auf die Solarzelle treffen, dann “stupsen”
            sie die dort vorhandenen Elektronen an. Die Elektronen geraten in
            Bewegung und wandern durch die Schichten der Solarzelle - es fließt
            Strom.
          </p>
        </div>
        <div>
          <img src={icon8Article3} alt="icon" />
          <p>
            <strong>Biomasse</strong>, das sind Holz, Getreide, Gülle, etc. -
            also Reste aus Land- und Forstwirtschaft. Diese Bestände (Substrate)
            werden zerkleinert und in Biogasanlagen mithilfe von Bakterien
            fermentiert, bzw. vergärt. Dabei entsteht Biogas (und als
            Restprodukt Dünger), das im Anschluss verbrannt wird. Dadurch wird
            Strom erzeugt.
          </p>
        </div>
        <div>
          <img src={icon9Article3} alt="icon" />
          <p>
            <strong>Geothermie</strong> ist Erdwärme. Zum Beispiel wird
            Thermalwasser über Bohrungen an die Oberfläche befördert und kann
            dann zur Wärme- oder Stromerzeugung genutzt werden. Damit aus
            Erdwärme Strom wird, muss die Wärme eine Dampfturbine antreiben. In
            manchen Anlagen wird dazu zusätzlich eine organische Flüssigkeit mit
            niedrigem Siedepunkt eingesetzt.
          </p>
        </div>
      </div>

      <p>
        So, damit wäre (oberflächlich und stark vereinfacht) geklärt, was grüner
        Strom ist und wie er entsteht.
      </p>
      <p>Aber halt - da war ja noch was...</p>
      <h2>Die Frage der Nachhaltigkeit</h2>
      <p>
        Strom aus erneuerbaren Energien ist sehr viel umwelt- und
        klimafreundlicher als der aus fossilen Brennstoffen, weil kaum CO
        <sub>2</sub> bei der Stromproduktion entsteht. Klingt gut - ist es auch.
      </p>
      <p>Aber es ist nicht automatisch nachhaltig!</p>
      <p>
        Nehmen wir ein ganz konkretes Beispiel: Biogas ist ja, wie oben erwähnt,
        Strom aus einer erneuerbaren Energiequelle.
      </p>
      <p>
        Jetzt stellen wir uns folgendes vor: Holz wird in der Biogasanlage zu
        Biogas verarbeitet. Dabei wird CO<sub>2</sub> frei, aber nur so viel,
        wie der Baum beim Wachsen aus der Atmosphäre gezogen hat. Also: kein
        zusätzliches CO<sub>2</sub>, kein zusätzlicher Treibhauseffekt - grün
        und nachhaltig!
      </p>
      <h2>Aber!</h2>
      <p>
        Das Holz muss ja schließlich von irgendwoher kommen. Wenn man nun für
        die Biogasproduktion ganze Waldstücke abholzt, geht damit ein
        Sammelbecken für Kohlenstoff verloren, eine sogenannte Kohlenstoffsenke.
        Das heißt, der Kohlenstoff in der Atmosphäre kann nicht mehr von den
        Pflanzen gebunden werden. Wenn ein solches Auffangbecken verloren geht,
        dann ist das natürlich alles andere als nachhaltig.
      </p>
      <p>Deshalb ist Strom aus Biogas zwar grün, aber erst nachhaltig, wenn</p>
      <ul>
        <li>
          nur vereinzelte Bäume an einer Stelle entnommen werden (das Ökosystem
          bleibt intakt).
        </li>
        <li>
          keine langen Transportwege von der Abholzungsstelle bis zur Anlage
          anfallen.
        </li>
        <li>
          nachgepflanzt wird. Allerdings muss man bedenken, dass es teilweise
          Jahrzehnte dauert, bis die beim Verbrennen freigewordene Menge an CO
          <sub>2</sub> wieder durch die nachwachsenden Bäume gebunden ist.
        </li>
        <li>
          Holz verwendet wird, das schon “ein Leben hinter sich hat”, also
          bereits genutzt wurde. Alte Möbelstücke zum Beispiel.
        </li>
      </ul>
      <p>
        Wir sehen: es kommt bei nachhaltigem Strom nicht nur auf die Art der
        erneuerbaren Energiequelle an, sondern auch wie diese genutzt wird.
      </p>
      <h2>Und wie ist das bei stromee mit der Nachhaltigkeit?</h2>
      <p>Bei stromee gibt es 100 % Ökostrom aus Wasserkraft. Warum?</p>
      <p>
        Mit unserer Entscheidung für Wasserkraft möchten wir bei stromee dazu
        beitragen, dass mehr nachhaltiger Ökostrom genutzt wird.
      </p>
      <p>
        Europa bietet so viele gute Möglichkeiten aus den natürlichen
        Wasserreservoirs Kraft zu schöpfen: Wir haben die Fjorde in
        Skandinavien, die Gletscherseen in Norwegen, die Bergseen in den Alpen,
        die Gezeitenkräfte der Atlantikküsten. In vielen europäischen Ländern
        sind die natürlichen Gegebenheiten günstig für Wasserkraft.{" "}
      </p>
      <p>
        Deshalb können in diesen Gebieten nachhaltigere Anlagen gebaut werden
        als in Gegenden, die nicht von Natur aus für Wasserkraftanlagen geeignet
        sind.
      </p>
      <p>
        Natürlich sind auch andere erneuerbare Energien wie Windkraft oder
        Solarenergie eine gute Option. Bei Ökostrom und vor allem auch bei
        nachhaltigem Strom geht es ja eigentlich auch nicht darum, welche Art
        der Energieerzeugung “am besten” ist. Jede erneuerbare Energiequelle hat
        ihre ganz eigenen Stärken und Vorzüge und es kommt dann bei der
        Entscheidung für eine bestimmte Energiequelle eher darauf an, auf welche
        Vorteile und Eigenschaften man besonderen Wert legt.
      </p>
      <p>
        <strong>Schon stromee Kunde? Wechsle heute noch zu strom</strong>
      </p>
      <ButtonsWrapper>
        <StoreButton
          url="https://apps.apple.com/de/app/id1521872083"
          target="_blank"
          text="App Store"
          className={styles.footer__storeButton}
        />
        <StoreButton
          url="https://play.google.com/store/apps/details?id=com.stromee.android"
          target="_blank"
          text="Google Play"
          className={styles.footer__storeButton}
        />
      </ButtonsWrapper>
    </div>
  );
};

export default Article3;
